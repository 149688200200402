//auth
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const PROFILE = 'PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_FORM = 'RESET_FORM';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const GET_LIST = 'GET_LIST';
export const GET_ALL_LIST = 'GET_ALL_LIST';
export const DELETE_DATA = 'DELETE_DATA';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const GET_DETAIL = 'GET_DETAIL';
export const CREATE_DATA = 'CREATE_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const DASHBOARD_COUNT = 'DASHBOARD_COUNT';
export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const GET_CONTENT = 'GET_CONTENT';
export const ADD_UPDATE_SLOT = 'ADD_UPDATE_SLOT';
export const GENERATE_EXCEL = 'GENERATE_EXCEL';
export const CREATE_OTHER_DATA = 'CREATE_OTHER_DATA';
export const GET_OTHER_DATA = 'GET_OTHER_DATA';
export const GET_CONTENT_APP = 'GET_CONTENT_APP';
export const DELETE_OTHERS = 'DELETE_OTHERS';
export const GET_OTHER_LIST = 'GET_OTHER_LIST';

export const PERSIST_STORE = 'persist/REHYDRATE';
