import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link, useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import env from 'env.json';
import {  reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';
import { Formik, Field, ErrorMessage  } from 'formik';
import Modal from 'react-awesome-modal';
import * as Yup from 'yup';
import moment from "moment";

const View = (props) => {
    //history
    const history = useHistory();
    const MODULE_NAME = 'user';
    const [modal, setModal] = useState(false)

    let editkey = props && props.match.params.id;
    const [sending, setSending] = useState(false);
    const { handleSubmit, submitting} = props;
    const [data, setData] = useState({});

    const todayDate = moment().format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')
    const [finalEndDate, setFinalEndDate] = useState(endDate);
    const [startDate, setStartDate] = useState("");
    const [newValues, setNewValues] = useState({});
   
    const initialValues = {
        first_name: data ? data.first_name : "",
        last_name: data ? data.last_name : "",
        email: data ? data.email : "",
        gender: data?.gender==1 ? 'male' : data.gender==2 ? 'female' : "others",
        phone: data ? data.country_code+data.phone : "",
        dob: data ? data.dob : "",
    };

    //get data
    async function getData() {
        try{
            let query={
                MODULE_NAME,
                id:editkey
            }
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                // console.log(res, "res")
                props.dispatch(actions.persist_store({ loader:false}));
                setSending(false);
                setData(res.body);
            });
        }catch(err) {
            setSending(false);
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }


       useEffect(() => {
            if(editkey){
                getData();
            }
       }, []);
      
        
    return(
        <>
            <Helmet title="Users" />
            <section className="section">
                <div className="section-header">
                    <h1>User Detail</h1>
                </div>
                {!props.loader &&
                <>
                <div className="section-body">
                        <div className="row mt-sm-4">
                            <div className="col-12 col-md-12 col-lg-7 offset-md-2">
                                <div className="card">
                                    <Formik
                                    initialValues={initialValues}
                                    enableReinitialize
                                >
                                    {({
                                        values,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="card-header">
                                                <h4>Detail</h4>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>First Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="first_name"
                                                            value={values.first_name}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>LastName</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="last_name"
                                                            value={values.last_name}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Email</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="email"
                                                            value={values.email}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Gender</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="gender"
                                                            value={values.gender}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>DOB</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="dob"
                                                            value={values.dob}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Phone</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="phone"
                                                            value={values.phone}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                {(data && data.image) &&
                                                <div className="row">
                                                    <label className="lable-design">Image</label>
                                                    <div className="form-group col-md-12 col-12">
                                                    <a href={data.image ? env.SERVER_URL+data.image : ''} target="_blank"><img src={data && data.image ? env.SERVER_URL+data.image : '/assets/img/avatar/avatar-1.png'} className="img-box-details" /></a>
                                                    </div>
                                                </div>
                                                }
                                                {(data && data.cover_image) &&
                                                <div className="row">
                                                    <label className="lable-design">Cover Image</label>
                                                    <div className="form-group col-md-12 col-12">
                                                    <a href={data.cover_image ? env.SERVER_URL+data.cover_image : ''} target="_blank"><img src={data && data.cover_image ? env.SERVER_URL+data.cover_image : '/assets/img/avatar/avatar-1.png'} className="img-box-details" /></a>
                                                    </div>
                                                </div>
                                                }

                                                </div>
                                            <div className="card-footer text-right">
                                                <Link to={Path.users}>
                                                    <LoadingButton
                                                        type="button"
                                                        className="btn btn-primary"
                                                        loading={sending}
                                                        disabled={submitting}
                                                    >
                                                    Back
                                                    </LoadingButton>
                                                </Link>
                                            </div>
                                        </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                </div>
                </>
                }
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const ViewForm = reduxForm({
	form: 'view',
})(View);

export default connect(mapStateToProps, mapDispatchToProps)(ViewForm);