import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";
import Toggle from 'react-toggle';
import ReactToggle from './ReactToggle.css';
import SelectInput from 'components/shared/SelectInput';
import TextInput from 'components/shared/TextInput';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';
import env from 'env.json';


const Listing = (props) => {


    const { handleSubmit, submitting} = props;

    //pagination
    const [ activePage, setActivePage ] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
    const [pageRange, setPageRange] = useState(5);

    //modal
    const [open, setOpen] = useState(false);
    //userid
    const [coinUserId, setCoinUserId] = useState({});
    //values
    const [newValues, setNewValues] = useState({

    });
    const initialValues = {
        credit: newValues ? newValues.credit : 0,
    };

    //search record
    const [sortby, setSortby] = useState(1);
    const [clearSearch, setClearSearch] = useState(false);
    const [ values, setValues] = useState({
        search:"",
    });
    //excel
    const [excelData, setExcelData] = useState([]);

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

      //handle change
      const handleChanges2 = (e) => {
        const { name, value } = e.target;
        setNewValues({
            ...newValues,
            [name]: value
        });
    };
    //history
    const history = useHistory();

    const [sending, setSending] = useState(false);
    const [sendingSearch, setSendingSearch] = useState(false);
    const [data, setData] = useState([]);
    const MODULE_NAME = 'user';
    
    //get data
    async function getData() {
        try{
            let search = clearSearch==false ? values.search : '';
            let query={
                page: activePage,
                MODULE_NAME,
                sortby,
                search:search
            }
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getList(query)).then((res) => {
               props.dispatch(actions.persist_store({ loader:false}));
               setData(res.body.data);
               setItemsCountPerPage(res.body.per_page);
               setTotalItemsCount(res.body.total_count);
            });
 
        }catch(err) {
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }

    useEffect(() => {
        getData();
    }, [activePage, sortby, clearSearch]);

    
    const clearFilter = ()=>{
        setClearSearch(!clearSearch)
        setValues({
            search:""
        })
    }

    const handlePageChange = (page) => {
        setActivePage(page);
       window.scrollTo(0,0);
    }

    const handleSubmit_ = async (e) => {
        try{
            e.preventDefault();
            let query = {
                page: activePage,
                MODULE_NAME,
                sortby,
                search:values.search
            };
            props.dispatch(actions.persist_store({ loader:true}));
            setSendingSearch(true);
            await props.dispatch(AuthService.getList(query)).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                setSendingSearch(false);
                setData(res.body.data);
            });
        }catch(err) {
            console.log(err);
            setSendingSearch(false);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

   //update user status
   const updateStatus = async (id) => {
    try{
        let query = {
            id: id,
            MODULE_NAME
        };
        await props.dispatch(AuthService.updateStatus(query)).then((res) => {
            swal("Success!", res.message, "success");
            getData();
        });
        }catch(err) {
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }
    //delete user
	const popupDelete = id =>{
		try {
			swal({
				title: "Are you sure?",
				text: "Are you sure to Delete?",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
			.then(willDelete => {
				if (willDelete) {
                    try{
                        let query = {
                            id: id,
                            MODULE_NAME
                        };
                        props.dispatch(AuthService.deleteData(query)).then((res) => {
                            swal("Deleted!", res.message, "success");
                            getData();
                        });
                    }catch(err) {
                        console.log(err);
                        if(err && err.data && err.data.message){
                            swal("Oops!", err.data.message, "error");
                        }
                    }
                }
			});
		}
		catch(err) {
            console.log(err);
			swal("Oops!", "Something went wrong!", "error");
	    }
	}

    return(
        <>
            <Helmet title="Users" />
            <section className="section">
                <div className="section-header">
                    <h1>Users List</h1>
                </div>
           
                    <div className="section-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div class="card-header">
                                        <div className="col-md-6">
                                            <h4>Listing</h4>
                                        </div>
                                        <div className="col-md-2 select-box-size">
                                            <SelectInput
                                                name="sort_by"
                                                className="form-control"
                                                onChange={(value) => setSortby(value.target.value)}
                                            >
                                                <option value="" disabled>Sort by </option>
                                                <option value="0">Asc</option>
                                                <option value="1">Desc</option>
                                            </SelectInput>
                                        </div>
                                        <div className="col-md-4">
                                             <form onSubmit={handleSubmit_}>
                                                <div className="input-group search-bar">
                                                    <TextInput
                                                        name="search"
                                                        className="form-control"
                                                        placeholder="Search..." 
                                                        value={values.search}
                                                        onChange={handleChange}
                                                    >
                                                    </TextInput>
                                                    <div className="input-group-append">
                                                        <LoadingButton
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            loading={sendingSearch}
                                                        >
                                                            {!sendingSearch ? <i className="fa fa-search"></i> : '' }
                                                        </LoadingButton>
                                                    </div>
                                                    <div className="input-group-append" style={{ marginLeft:'3px'} }>
                                                        <LoadingButton
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={clearFilter}
                                                        >
                                                            <i className="fa fa-times"></i>
                                                        </LoadingButton>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="card-body"  style={{overflowY: 'auto'}}>
                                    {!props.loader && data.length>0 &&
                                    <>
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <tr>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Email</th>
                                                    <th>Image</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                                {data.length>0  && data.map((item, i) => {
                                                return(
                                                    <tr key={i}>
                                                        <td>{item.first_name}</td>
                                                        <td>{item.last_name}</td>
                                                        <td>{item.email}</td>
                                                        <td>
                                                            <img src={item.image && item.image_thumb ? env.SERVER_URL+item.image_thumb : '/assets/img/avatar/avatar-1.png'} className="img-size" />
                                                        </td>
                                                        <td>
                                                            <Toggle id='cheese-status' checked={item.status==1} onClick={()=> updateStatus(item.id)} />
                                                        </td>
                                                        <td>
                                                            <Link to={`${Path.userview}/${item.id}`} style={{ marginLeft:'10px', marginTop:'10px'}} className="btn btn-primary btn-sm" title="View">
                                                                <i className="fa fa-lg fa-eye"></i>
                                                            </Link>
                                                            <span style={{ marginLeft:'10px', marginTop:'10px'}} className="btn btn-danger btn-sm" title="Delete" onClick={() => {popupDelete(item.id)}}>
                                                                <i className="fa fa-trash"></i>
                                                            </span>
                                                            <Link to={`${Path.redeem_coupon}/${item.id}`} style={{ marginLeft:'10px', marginTop:'7px'}} className="btn btn-info btn-sm" title="Coupon">
                                                                <i className="fa fa-lg fa-eye"></i> Redeemed coupon
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </table>
                                        </div>
                                        <div className="pagination-box-review">
                                            {(totalItemsCount>itemsCountPerPage) &&
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={totalItemsCount}
                                                pageRangeDisplayed={pageRange}
                                                onChange={(page) => handlePageChange(page)}
                                                itemClass='page-item'
                                                linkClass='page-link'
                                            />
                                            }
                                        </div>
                                        </>
                                        }
                                        {!props.loader && data.length==0 && 
                                            <div className="col-md-6">
                                                <h6>Data not found....</h6>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader:state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing);