import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import { List } from 'react-content-loader';
import AuthService from 'services';
import swal from 'sweetalert';
import { Field, reduxForm, reset} from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';

const renderField = ({
    input,
    placeholder,
    type,
    autoComplete,
    disabled,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} disabled={disabled} autoComplete={autoComplete} className={`form-control ${touched  && error ? `is-invalid` : ''}`}/>
        
        {touched &&
            (error && <span className="invalid-feedback">{error}</span>)
        }
    </>
)

const ChangePassword = (props) => {
    const [sending, setSending] = useState(false);
    const [data, setData] = useState({});
    const [success, setSuccess] = useState(false);
    const { handleSubmit, submitting} = props;

    const [values, setValues] = useState({
        old_password: "",
        new_password: "",
        confirm_password: "",
    });

    const updatePassword = async (values) => {
        try{
            setSending(true);
            await props.dispatch(AuthService.updatePassword(values)).then((res) => {
                setSending(false);
                swal("Success", res.message, "success");
                props.dispatch(reset('changePassword'));
            });
        }catch(err) {
             console.log(err);
             setSending(false);
              if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    return(
        <>
            <Helmet title="Change Password" />
            <section className="section">
                <div className="section-header">
                    <h1>Change Password</h1>
                </div>

                <div className="section-body">
                        <div className="row mt-sm-4">
                            <div className="col-12 col-md-12 col-lg-7 offset-md-2">
                                <div className="card">
                                <form method="POST" onSubmit={handleSubmit(updatePassword)} autoComplete="off">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-md-12 col-12">
                                                <label>Old Password</label>
                                                <Field 
                                                    name="old_password" 
                                                    component={renderField}
                                                    type="password" 
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-12 col-12">
                                                <label>New Password</label>
                                                <Field 
                                                    name="new_password" 
                                                    component={renderField}
                                                    type="password" 
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-12 col-12">
                                                <label>Confirm Password</label>
                                                <Field 
                                                    name="confirm_password" 
                                                    component={renderField}
                                                    type="password" 
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="card-footer float-right col-md-4">
                                        <LoadingButton
                                            type="submit"
                                            className="btn btn-primary btn-lg btn-block"
                                            loading={sending}
                                            disabled={submitting}
                                        >
                                        {!sending ? "Save Changes" : ''}
                                        </LoadingButton>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    //old password
    if (!values.old_password) {
      errors.old_password = 'Password Required'
    }
    else if (values.old_password.length < 6) {
      errors.old_password = 'Must be 6 characters or grater'
    }
    //new_password
    if (!values.new_password) {
      errors.new_password = "New Password is required";
    }
    else if (values.new_password.length < 6) {
      errors.new_password = 'Must be 6 characters or grater'
    }
    //confirm _password
    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password is required";
    }
    else if (values.confirm_password !== values.new_password) {
      errors.confirm_password = "Confirm Password doesn't match with password"
    }
    return errors
  }
  

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const ChangePasswordForm = reduxForm({
	form: 'changePassword',
	validate,
    enableReinitialize: true,
})(ChangePassword);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);