import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import { reduxForm } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';
import ImageUploading from 'react-images-uploading';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from "moment";
import env from 'env.json';

const Add = (props) => {
    //history
    const history = useHistory();
    const MODULE_NAME = 'adds';

    const [sending, setSending] = useState(false);
    const { handleSubmit, submitting} = props;
    const [picture, setPicture] = useState(null);
    const initialValues = {
        video: picture ? picture : '',
    };
    const [imgData, setImgData] = useState(null);

     //handle change
     const handleChanges = (e) => {
        setPicture(null)
        setImgData(null)
        if (e.target.files[0]) {
            const selectedFile = e.target.files[0];
            const videoTypes = ["video/mp4", "video/avi", "video/mov"]; // Add more video MIME types as needed
        
            // Check if the selected file's MIME type is in the videoTypes array
            if (videoTypes.includes(selectedFile.type)) {
              // It's a video file
              setPicture(selectedFile);
              const reader = new FileReader();
              reader.addEventListener("load", () => {
                setImgData(reader.result);
              });
              reader.readAsDataURL(selectedFile);
            } else {
              // Not a valid video file
              swal("Oops!", "Please select a valid video file.", "error");
              // Optionally, you can reset the input to clear the selection
              e.target.value = null;
            }
        }
    };
   
    const submitData_ = async (values, { setSubmitting, resetForm }) => {
        try{
            if(picture==null){
                swal("Oops!", "Please select video", "error");  
            }else{
                const formData = new FormData(); 
                let query = {}
                formData.append('image', picture);
                formData.append('type', 'video');
                formData.append('folder', 'ads');
                setSending(true);
                await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
                    setSending(false);
                    let newImg = res.body;
                    query.video = JSON.stringify(newImg)
                });
                query = {
                    ...query,
                    MODULE_NAME,
                };
                setSending(true);
                await props.dispatch(AuthService.createData(query)).then((res) => {
                    setSending(false);
                    swal("Success", res.message, "success");
                    history.push(Path.ads);
                    
                });
            }
        }catch(err) {
            console.log(err);
            setSending(false);
             if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
       }
   }

    return(
        <>
            <Helmet title="Ads" />
            <section className="section">
                <div className="section-header">
                    <h1>Add Ads</h1>
                </div>
                {!props.loader &&
                <div className="section-body">
                    <div className="row mt-sm-4">
                        <div className="col-12 col-md-12 col-lg-7 offset-md-2">
                            <div className="card">
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validation}
                                onSubmit={submitData_}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="card-header">
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Video*</label>
                                                    <input 
                                                        name="video" 
                                                        accept="video/*"
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) => handleChanges(e)}
                                                    />
                                                    <p className="errorMessage">
                                                    <ErrorMessage
                                                        className="form-text text-danger"
                                                        name="video"
                                                    />
                                                    </p>
                                                </div>
                                            </div>
                                            {imgData!==null &&
                                            <div className="row">
                                                <div className="form-group col-md-6 col-12">
                                                    <video style={{with:'150px', height:'150px'}} controls >
                                                        <source src={imgData} type="video/mp4"/>
                                                    </video>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        
                                        <div className="card-footer float-right col-md-4">
                                            <LoadingButton
                                                type="submit"
                                                className="btn btn-primary btn-lg btn-block"
                                                loading={sending}
                                                disabled={submitting}
                                            >
                                            {!sending ? 'Save Changes' : ''}
                                            </LoadingButton>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </section>
              
        </>
    )
}


const validation = Yup.object().shape({
    video: Yup.string().trim().required("Required*"),  
});

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const AddForm = reduxForm({
	form: 'add',
    enableReinitialize:true
})(Add);

export default connect(mapStateToProps, mapDispatchToProps)(AddForm);