import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import { reduxForm } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import env from 'env.json';
import ImageUploader from 'react-images-upload';
import Toggle from 'react-toggle';
import ReactToggle from './ReactToggle.css';
import ImageUploading from 'react-images-uploading';
import Geosuggest from 'react-geosuggest';
import './geosuggest.css'; // Import the CSS file
import Modal from 'react-awesome-modal';
import Select from 'react-select';

const Edit = (props) => {
    const MODULE_NAME = 'restaurant';
    //history
    const history = useHistory();
    let editkey = props && props.match.params.id;

    const [sending, setSending] = useState(false);
    const [closed, setClosed] = useState(false);
    const [data, setData] = useState({});
  
    const { handleSubmit, submitting} = props;
 
    //images
    const [ images, setImages] = useState([]);    
    const [ imageError, setImageError] = useState(false);    
    const maxNumber = 5;

    //resto_menu 
    const [pdf, setPdf] = useState(null);
    const [pdfName, setPdfName] = useState("");
 
    // const [ hoursId, setHoursId] = useState({});  
    const [ restaurantId, setRestaurantId] = useState({});  
    //modal
    const [modal, setModal] = useState(false)

    //type
    const [ typeId, setTypeId] = useState(null); 
    //offer type
    const [ offerType, setOfferType] = useState([]);
    //location   
    const [ locations, setLocations] = useState([]); 

    const [ typeCatgeoryId, setTypeCatgeoryId] = useState(null); 
    const [ offerCategoryType, setOfferCatgeoryType] = useState([]); 

    //selected tags option 
    const [selectOption, setSelectOption] = useState([]);
    const [tagsOptions, setTagsOptions] = useState([]);
    //db states
    const [restoTags, setRestoTags] = useState([]);
    const [restoCuisines, setRestoCuisines] = useState([]);

    //selected cuisine option 
    const [selectCuisineOption, setSelectCuisineOption] = useState([]);
    const [cuisineOptions, setCuisineOptions] = useState([]);
 

    //location
    const [ locationId, setLocationId] = useState(null);  

    const [locationValues, setLocationValues] = useState({
        'location':'',
        'latitude':'',
        'longitude':''
    });

    //show link pdf
    const [showDesc, setShowDesc] = useState(false);

    const [start_time, period] =props?.editHour ?  props?.editHour?.start_time?.split(' ') : '';
    const [hour, minute] = props?.editHour ? start_time?.split(':') : '';

    const [end_time, end_period] = props?.editHour ? props?.editHour?.end_time?.split(' ') : '';
    const [end_hour, end_minute] = props?.editHour ? end_time?.split(':') : '';

    const initialValues = {
        name: data ? data.name : '',
        title: data ? data.title : '',
        email: data ? data.email : '',
        phone: data ? data.phone : '',
        website_url: data ? data.website_url : "",
        facebook_url: data ? data.facebook_url : '',
        instagram_url: data ? data.instagram_url : '',
        pdf_link: data ? data.menu : '',
        location: locationValues ? locationValues.location : "",
        latitude: locationValues ? locationValues.latitude : "",
        longitude: locationValues ? locationValues.longitude : "",
        reservation_url: data ? data.reservation_url : '',
        social_url: data ? data.social_url : '',
        currency_type: data ? data.currency_type : '',
        tag_ids: '',
        cuisine_ids:'',
        description: data ? data.description : '',
        offer_type_id: data ? data.offer_type_id : '',
        offer_type_category_id: data ? data.offer_type_category_id : '',
        location_id: data ? data.location_id : '',
        open_hours: [
            {
              title: '',
              start_time: '',
              end_time: '',
              start_time_format: '',
              end_time_format: '',
            },
        ],
    };

    const initialValuesHours ={
        title: props.editHour ? props.editHour.title : '',
        start_time: props.editHour ? start_time : '', 
        end_time: props.editHour ?  end_time : '', 
        start_time_format: props.editHour ? period : '', 
        end_time_format: props.editHour ?  end_period : '', 
        is_closed: props.editHour ? props.editHour.is_closed : '', 
    }
  
    const openModal = (hours) => {
        setModal(true);
        setClosed(false)
        setRestaurantId(hours.id);
        props.dispatch(actions.persist_store({ editHour:""}));
    };

    const editModal = async (hours) => {
        props.dispatch(actions.persist_store({ editHour:hours}));
        setModal(true);
    };
    //close modal
    const closeModal = () => {
        setModal(false);
        setClosed(false)
        props.dispatch(actions.persist_store({ editHour:""}));
    };

     //select tags
    const handleChangeSelect = (value) => {
        setSelectOption(value);
    };

    //select cuisine
    const handleChangeSelectCuisine = (value) => {
        setSelectCuisineOption(value);
    };

    //get option tags 
    async function getTags() {
        try{
            let query ={
                MODULE_NAME,
                URL_NAME : 'tag_list'
            }
           setSending(false);
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
               setSending(false);
               props.dispatch(actions.persist_store({ loader:false}));
            //    setTagsOtions(res.body);
               let tag_options = res.body
               let arr = []
                for(let option of tag_options){
                    // if(model.dashboard==true){
                        let data = {
                            value : option.id,
                            label : option.name
                        }
                        arr.push(data)
                    // }
                }
                setTagsOptions(arr);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }

    //get option cuisine 
    async function getCuisine() {
        try{
            let query ={
                MODULE_NAME,
                URL_NAME : 'cuisine_list'
            }
           setSending(false);
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
               setSending(false);
               props.dispatch(actions.persist_store({ loader:false}));
            //    setTagsOtions(res.body);
               let cuisine_options = res.body
               let arr = []
                for(let option of cuisine_options){
                    // if(model.dashboard==true){
                        let data = {
                            value : option.id,
                            label : option.name
                        }
                        arr.push(data)
                    // }
                }
                setCuisineOptions(arr);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }



    //get offer type
    async function getOfferTypes() {
        try{
            let query ={
                MODULE_NAME,
                URL_NAME : 'offer_type_list_with_category'
            }
            setSending(false);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
                setSending(false);
                props.dispatch(actions.persist_store({ loader:false}));
                setOfferType(res.body);
            });
    
        }catch(err) {
            setSending(false);
            props.dispatch(actions.persist_store({ loader:false}));
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    //get location 
    async function getLocation() {
        try{
            let query ={
                MODULE_NAME,
                URL_NAME : 'location_list'
            }
           setSending(false);
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
               setSending(false);
               props.dispatch(actions.persist_store({ loader:false}));
               setLocations(res.body);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }

    //show link pdf
    const showDescriptionFunction = (val) => {
        setShowDesc(val)
    }
    

    //get data
    async function getData() {
        try{
            let query={
                MODULE_NAME,
                id:editkey
            }
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                props.dispatch(actions.persist_store({ edit:res.body}));
                props.dispatch(actions.persist_store({ loader:false}));
                setSending(false);
                setData(res.body);
                let menuType = res.body.menu_type
                menuType = menuType==2 ?  true : false
                setShowDesc(menuType)
                let newArr = [];
                let img = res.body.restaurant_images;
                img.map((item, i) => {
                    newArr.push(item);
                })
                setImages(newArr);
                getOfferCategory(res.body.offer_type_id)
                setLocationValues({
                    'location': res.body.location,
                    'latitude': res.body.latitude,
                    'longitude': res.body.longitude
                });
                setRestoTags(res.body.restaurant_tags)
                setRestoCuisines(res.body.restaurant_cuisines)
            });
        }catch(err) {
            setSending(false);
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    useEffect(() => {
        if(editkey){
            getData();
        }
        getOfferTypes();
        getLocation();
        getTags();
        getCuisine();
    }, []);

    useEffect(() => {
        if(editkey){
            let finalArr = []
            for(let val of restoTags){
                let data = {
                    value : val?.tag?.id,
                    label : val.tag?.name
                }
                finalArr.push(data)
            }
            setSelectOption(finalArr);

            let finalArr2 = []
            for(let val of restoCuisines){
                let data = {
                    value : val?.cuisine?.id,
                    label : val.cuisine?.name
                }
                finalArr2.push(data)
            }
            setSelectCuisineOption(finalArr2);

        }

    }, [restoTags, restoCuisines]);

     //get offer category
     async function getOfferCategory(id) {
        try{
            let query ={
                MODULE_NAME : 'restaurant_offer',
                URL_NAME : 'category_by_offer_type',
                id: id
            }
           setSending(false);
        //    props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
               setSending(false);
            //    props.dispatch(actions.persist_store({ loader:false}));
               setOfferCatgeoryType(res.body);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }

   /* //start time----------------------
   var x = 60; // minutes interval
   var start_times = []; // time array
   var tt = 0; // start time
   
   // loop to increment the time and push results in array
   for (var i = 1; i <= 12; i++) {
       var hh = i; // hours from 1 to 12
       var mm = 0; // minutes at the beginning of the hour
       start_times.push(("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2));
   }

   
   //end time----------------------
   var x = 60; // minutes interval
   var end_times = []; // time array
   var tt = 0; // start time
   
   // loop to increment the time and push results in array
   for (var i = 1; i <= 12; i++) {
       var hh = i; // hours from 1 to 12
       var mm = 0; // minutes at the beginning of the hour
       end_times.push(("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2));
   } */

     
        //start time----------------------
        var x = 30; // minutes interval
        var start_times = []; // time array
        var hh = 1; // start hour
        var mm = 0; // start minute

        // loop to increment the time and push results in array for 12 hours
        for (var i = 0; i < (12 * 60 / x); i++) {
            start_times.push(("" + hh).slice(-2) + ':' + ("0" + mm).slice(-2));
            
            // Increment time by 30 minutes
            mm += x;

            // Check if minutes exceed 59, then increment hours and reset minutes
            if (mm >= 60) {
                mm = 0;
                hh += 1;
            }

            // If hours exceed 12, reset to 1
            if (hh > 12) {
                hh = 1;
            }
        }
     
        //end time----------------------
        var x = 30; // minutes interval
        var end_times = []; // time array
        var hh = 1; // start hour
        var mm = 0; // start minute

        // loop to increment the time and push results in array for 12 hours
        for (var i = 0; i < (12 * 60 / x); i++) {
            end_times.push(("" + hh).slice(-2) + ':' + ("0" + mm).slice(-2));
            
            // Increment time by 30 minutes
            mm += x;

            // Check if minutes exceed 59, then increment hours and reset minutes
            if (mm >= 60) {
                mm = 0;
                hh += 1;
            }

            // If hours exceed 12, reset to 1
            if (hh > 12) {
                hh = 1;
            }
        }

    //profile image 
    const uploadPdf = (picture, base64) => {
        setPdfName(picture[0].name);
        setPdf(picture[0]);
    }
    
     //render menu pdf
	const renderMenuPDF = (name, buttonText) => {
        let defaultPDF = '';
        // if(dbPdf && name==='resto_menu'){
        //     defaultPDF = dbPdf && dbPdf.original ? env.SERVER_URL+dbPdf.original : '';
        // }
        return(
             <div className="profile_images_boxs pdf-design">
                 <ImageUploader
                     name={name}
                     withIcon={false}
                     withPreview={false}
                     singleImage={true}
                     buttonText={buttonText}
                     onChange={(picture) => uploadPdf(picture, name)}
                     imgExtension={[".pdf"]}
                     fileSizeError="file size is too big"
                     accept="application/pdf"
                     label={<div className="label-image-profile">{pdfName ? pdfName : '' }</div>}
                    //  defaultImages={ defaultImage ? [defaultImage] : [] }
                 />
                 {(pdf==null && data.menu) &&
                    <>
                        <p>
                            <a href={data && data.menu ? env.SERVER_URL+data.menu : '#'} target="_blank">
                                click here to view pdf
                            </a>
                        </p>
                    </>
                    
                }
             </div>
         )
    };

    const _renderHoursForm = () => {
        return(

                <div className="col-md-12">
                    <div className="card">
                    <Formik
                        initialValues={initialValuesHours}
                        enableReinitialize
                        validationSchema={validationHours}
                        onSubmit={submitFunctionHours}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                        <form onSubmit={handleSubmit}>
                             <div style={{marginTop:'10px'}}>
                                <h6>{props.editHour ? 'Edit' : 'Add'}</h6><br/>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-4">
                                    <label>Title*</label>
                                    <input 
                                        name="title" 
                                        type="text"
                                        className="form-control"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.title}
                                        maxLength={100}
                                    />
                                    <p className="errorMessage">
                                    <ErrorMessage
                                        className="form-text text-danger"
                                        name="title"
                                    />
                                    </p>
                                </div>
                                {(closed==false || props?.editHour?.is_closed==false) &&
                                <>
                                <div className="form-group col-md-3">
                                    <label>Start Time*</label>
                                    <Field as="select" class="form-control" name="start_time"  onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.start_time}>
                                        <option value="" disabled>--:--</option>
                                        {start_times.length>0 && start_times.map((time, i)=>{
                                            return(
                                                <option value={time}>{time}</option>
                                            )
                                        })}
                                    </Field>
                                    <p className="errorMessage">
                                    <ErrorMessage
                                        className="form-text text-danger invalid-feedback"
                                        name="start_time"
                                    />
                                    </p>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>AM/PM*</label>
                                    <Field as="select" class="form-control" name="start_time_format"  onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.start_time_format}
                                    >
                                        <option value="" disabled>--:--</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                       
                                    </Field>
                                    <p className="errorMessage">
                                    <ErrorMessage
                                        className="form-text text-danger invalid-feedback"
                                        name="start_time_format"
                                    />
                                    </p>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>End Time*</label>
                                    <Field as="select" class="form-control" name="end_time"  onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.end_time}>
                                        <option value="" disabled>--:--</option>
                                        {end_times.length>0 && end_times.map((time1, i)=>{
                                            return(
                                                <option value={time1}>{time1}</option>
                                            )
                                        })}
                                    </Field>
                                    <p className="errorMessage">
                                    <ErrorMessage
                                        className="form-text text-danger invalid-feedback"
                                        name="end_time"
                                    />
                                    </p>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>AM/PM*</label>
                                    <Field as="select" class="form-control" name="end_time_format"  onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.end_time_format}
                                    >
                                        <option value="" disabled>--:--</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                       
                                    </Field>
                                    <p className="errorMessage">
                                    <ErrorMessage
                                        className="form-text text-danger invalid-feedback"
                                        name="end_time_format"
                                    />
                                    </p>
                                </div>
                                </>
                                }
                                <div className="form-group col-md-1">
                                    <label> Is Closed</label>
                                    <Field
                                        type="checkbox"
                                        name="is_closed"
                                        style={{ margin: '10px' }}
                                        // checked={}
                                        onClick={() => setClosed(!closed)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="text-right">
                                <LoadingButton
                                    type="submit"
                                    className="btn btn-primary"
                                    loading={sending}
                                    disabled={submitting}
                                >
                                {props.editHour ? 'Update' : 'Save'}
                                </LoadingButton>
                            </div>
                        </form>
                        
                    )}
                    </Formik>
                    </div>
                </div>
        )
    }

    const submitFunctionHours = async (values, { setSubmitting, resetForm }) => {
        try{
            let query = {};
            let type = props?.editHour ? 2 : 1;
            query = {
                ...query,
                MODULE_NAME,
                title:values.title,
                // hours_id:props.editHour ? props.editHour.id: hoursId,
                restaurant_id:props.editHour ? props.editHour.restaurant_id : restaurantId,
                start_time:values.start_time+' '+values.start_time_format,
                end_time:values.end_time+' '+values.end_time_format,
                is_closed:values.is_closed ? values.is_closed : false,
                type:type,
            }
            if(type==2){
                query.hours_id= props.editHour?props.editHour.id:'';
                query.restaurant_id= props.editHour?props.editHour.restaurant_id:'';
            }
            // returnsetSending(false);
            await props.dispatch(AuthService.addUpdateSlot(query)).then((res) => {
                setSending(false);
                swal("Success", res.message, "success");
                setModal(false);
                getData();
                resetForm();
            });

        }catch(err) {
            console.log(err);
            setSending(false);
             if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }

    }
    const popupDelete = hours_id =>{
		try {
			swal({
				title: "Are you sure?",
				text: "Are you sure to Delete?",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
			.then(willDelete => {
				if (willDelete) {
                    try{
                        let query = {
                            hours_id: hours_id,
                            MODULE_NAME,
                            URL_NAME:'delete_hours'
                        };
                        props.dispatch(AuthService.deleteOthers(query)).then((res) => {
                            swal("Deleted!", res.message, "success");
                            getData();
                        });
                    }catch(err) {
                        console.log(err);
                        setSending(false);
                        if(err && err.data && err.data.message){
                            swal("Oops!", err.data.message, "error");
                        }
                    }
                }
			});
		}
		catch(err) {
            console.log(err);
			swal("Oops!", "Something went wrong!", "error");
	    }
	}
 
    //images
    let emptyArray = [...images]
    const onChange = (imageList) => {
        if(images.length>maxNumber){
            setImageError(true);
        }else{
            setImageError(false);
            imageList.forEach((image, index) => {
                emptyArray.push(image)
                setImages([...images, image]);
            });
            emptyArray = getUniqueListBy(emptyArray, 'data_url')
            let myNewImageArray = emptyArray.filter(function( obj ) {
                return obj.data_url
            });
            let myDbImageArray = images.filter(function( obj ) {
                return !obj.data_url
            });
            setImages([...myDbImageArray, ...myNewImageArray])
        }  
    };

    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

  
    const handleRemoveImgClick = async (img) => {
        let arr;
        if(img.data_url){
            arr = images.filter((item) => item.data_url !== img.data_url);
        }else{
            let query = {
                MODULE_NAME:'restaurant/image',
                id:img.id,
            };

            await props.dispatch(AuthService.deleteData(query)).then((res) => {
               arr = images.filter((item) => item.image !== img.image);
            });
        }
        setImages(arr);
    };

    const restaurant_images = () => {
        return (
           <>
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                >
                {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors,
                }) => (
                    // write your building UI
                    <>
                    <div className="form-row " style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}>
                        <img src="/assets/img/news/img01.jpg" onClick={() => onImageRemove(0)} style={{width:'100px', height:'100px', margin:'12px'}} />
                    </div>
                    {errors && <div style={{color:'red'}}>
                    {errors.maxNumber && <span>You can select upto 5 images same time</span>}
                    </div>}
                    <div className="row">
                        {images.length>0 && images.map((image, index) => {
                            let imgData;
                            if(image['data_url']){
                                imgData = image['data_url'];
                            } else{
                                imgData = env.SERVER_URL+image.image;
                            }
                            return(
                            <div className="row">    
                            <div className="col-md-4">    
                                <div className="image-item-event" key={index}>
                                    <div className="close-img-item" onClick={() => handleRemoveImgClick(image)}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                    <img src={imgData} alt="" style={{width:'100px', height:'100px', marginLeft:'20px'}}/>
                                </div>
                            </div>
                            </div>
                            )
                        })}
                    </div>
                    </>
                )}
            </ImageUploading> 
           </>
        )

    }

     //handle change type
     const handleChangeType = (e) => {
        const { name, value } = e.target;
        setTypeId({
            ...typeId,
            [name]: value
        });
        // getOfferCategory(value)
    };

     //handle change location
     const handleChangeLocation = (e) => {
        const { name, value } = e.target;
        setLocationId({
            ...locationId,
            [name]: value
        });
        // getOfferCategory(value)
    };

    //submit data=================================
    const submitData_ = async (values, { setSubmitting, resetForm }) => {
        try{
                let finalArrTags = []
                for(let option of selectOption){
                    let modules_data = {
                        value : option.value,
                    }
                    finalArrTags.push(modules_data.value)
                }
                let finalArrCuisine = []
                for(let options of selectCuisineOption){
                    let modules_data = {
                        value : options.value,
                    }
                    finalArrCuisine.push(modules_data.value)
                }
                let query = {};
                const formData = new FormData(); 
                const formData2 = new FormData(); 
                    if(images.length==0){
                        setImageError(true);
                    }else{
                    let myNewImageArray = images.filter(function( obj ) {
                        return obj.data_url
                    });
                    let myDbImageArray = images.filter(function( obj ) {
                        return !obj.data_url
                    });
                    if(myNewImageArray.length > 0 && myNewImageArray[0]['data_url']){
                        for ( let key in myNewImageArray ) {
                            if(myNewImageArray[key]['file']){
                                formData.append('image', myNewImageArray[key]['file']);
                            }
                        }
                        query.image = myNewImageArray.length > 0 && myNewImageArray[0]['data_url'] ? [...myNewImageArray] : ''
                        formData.append('type', 'image');
                        formData.append('folder', 'restaurant');
                        setSending(true);
                        await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
                            setImageError(false);
                            let newImg = res.body;
                            if(editkey){
                                if(myDbImageArray.length > 0){
                                    for(let i in myDbImageArray){
                                        myDbImageArray[i].file_type = 'image'
                                        myDbImageArray[i].folder = 'restaurant'
                                        myDbImageArray[i].image = myDbImageArray[i].image
                                        myDbImageArray[i].thumbnail = myDbImageArray[i].image_thumb
                                    }
                                }
                                let newArrayForSave = [...newImg, ...myDbImageArray]
                                query.image = JSON.stringify(newArrayForSave)
                            }else{
                                setSending(false);
                                query.image = JSON.stringify(newImg)
                            }
                        })
                    }
                    if(pdf!=null && editkey &&  showDesc==false){
                        formData2.append('image', pdf);
                        formData2.append('type', 'pdf');
                        formData2.append('folder', 'restaurant');
                        await props.dispatch(AuthService.fileUpload(formData2)).then((res) => {
                            let newPdf = res.body;
                            setSending(false);
                            query.resto_menu = JSON.stringify(newPdf)
                        
                        });
                    }
                    if(showDesc){
                        query.resto_menu = values.pdf_link
                    }
                    setSending(true);
                    query = {
                        ...query,
                        MODULE_NAME,
                        name:values.name,
                        title:values.title,
                        email:values.email,
                        phone:values.phone,
                        description:values.description,
                        currency_type:values.currency_type,
                        website_url:values.website_url,
                        facebook_url:values.facebook_url,
                        instagram_url:values.instagram_url,
                        reservation_url:values.reservation_url,
                        social_url:values.social_url,
                        latitude:values.latitude,
                        longitude:values.longitude,
                        location:values.location,
                        menu_type:showDesc ? 2 : 1,
                        price:'0.0',
                        google_avg_count:'0.0',
                        restaurant_id:editkey,
                        offer_type_id:typeId ? parseInt(typeId.offer_type_id) : data.offer_type_id,
                        offer_type_category_id: typeCatgeoryId ? parseInt(typeCatgeoryId.offer_type_category_id) : data.offer_type_category_id,
                        location_id:locationId ? parseInt(locationId.location_id) :data.location_id,
                        tag_ids:JSON.stringify(finalArrTags),
                        cuisine_ids:JSON.stringify(finalArrCuisine),
                    }
                    await props.dispatch(AuthService.updateData(query)).then((res) => {
                        setSending(false);
                        swal("Success", res.message, "success");
                        history.push(Path.restaurant);
                        
                    });
                }
        } catch(err) {
             console.log(err);
             setSending(false);
              if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    const handleChangeTypeCategory = (e) => {
        const { name, value } = e.target;
        setTypeCatgeoryId({
            ...typeCatgeoryId,
            [name]: value
        });
    };

    const onPlaceSelected = (place) => {
        const address = place.description;
        const latValue = place.location.lat;
        const  lngValue = place.location.lng;
        setLocationValues({
            'location': address,
            'latitude': latValue,
            'longitude': lngValue
        });
    };

    return(
        <>
            <Helmet title="Business" />
            <section className="section">
                <div className="section-header">
                    <h1>Edit Business</h1>
                </div>
                <Modal visible={modal} width="600px" height="350px" onClickAway={closeModal}>
                    {_renderHoursForm()}
                </Modal>
                {!props.loader &&
                <div className="section-body">
                    <div className="row mt-sm-4">
                        <div className="col-12 col-md-12 col-lg-7 offset-md-2">
                            <div className="card">
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={validation}
                                onSubmit={submitData_}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="card-header">
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Type*</label>
                                                    <select 
                                                        className={`form-control ${errors.offer_type_id && 'is-invalid'}`}
                                                        name="offer_type_id"
                                                        onChange={handleChangeType}
                                                        required
                                                        disabled
                                                    >
                                                        <option value="" selected disabled>Select</option>
                                                        {offerType.length>0 && offerType.map((item, key) => {
                                                            return(
                                                                <option key={key} value={item.id} selected={item.id==data.offer_type_id}>
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        })}
                                                    
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Category*</label>
                                                        <select 
                                                            className={`form-control ${errors.offer_type_category_id && 'is-invalid'}`}
                                                            name="offer_type_category_id"
                                                            onChange={handleChangeTypeCategory}
                                                            // onBlur={handleBlur}
                                                            required
                                                            // disabled
                                                        >
                                                            <option value="" selected disabled>Select</option>
                                                            {offerCategoryType.length>0 && offerCategoryType.map((item, key) => {
                                                                return(
                                                                    <option key={key} value={item.id} selected={item.id==data.offer_type_category_id}>
                                                                        {item.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Location*</label>
                                                        <select 
                                                            className={`form-control ${errors.offer_type_id && 'is-invalid'}`}
                                                            name="location_id"
                                                            onChange={handleChangeLocation}
                                                            required
                                                            // disabled
                                                        >
                                                            <option value="" selected disabled>Select</option>
                                                            {locations.length>0 && locations.map((item, key) => {
                                                                return(
                                                                    <option key={key} value={item.id} selected={item.id==data.location_id}>
                                                                        {item.address}
                                                                    </option>
                                                                );
                                                            })}
                                                        
                                                        </select>
                                                        {(errors.location_id && touched.location_id) &&
                                                            <span className="invalid-feedback">{errors.location_id}</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row">                               
                                                    <div className="form-group col-md-12 col-6">
                                                        <label>Atmosphere</label>
                                                        <Select
                                                            value={selectOption}
                                                            onChange={handleChangeSelect}
                                                            options={tagsOptions}
                                                            isMulti={true}
                                                        />
                                                        {(errors.tag_ids && touched.tag_ids) &&
                                                            <span className="invalid-feedback">{errors.tag_ids}</span>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="row">                               
                                                    <div className="form-group col-md-12 col-6">
                                                        <label>Lifestyle</label>
                                                        <Select
                                                            value={selectCuisineOption}
                                                            onChange={handleChangeSelectCuisine}
                                                            options={cuisineOptions}
                                                            isMulti={true}
                                                        />
                                                        {(errors.cuisine_ids && touched.cuisine_ids) &&
                                                            <span className="invalid-feedback">{errors.cuisine_ids}</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Address*</label>
                                                        <div style={{ cursor: 'pointer' }}>
                                                        <Geosuggest
                                                            placeholder="Enter an address"
                                                            onSuggestSelect={onPlaceSelected}
                                                            style={{ width: '100%', height: '40px', fontSize: '16px', borderRadius: '4px',    }}
                                                            inputClassName="form-control"
                                                            initialValue={values.location}
                                                        />
                                                        </div>
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="location"
                                                        />
                                                        </p>
                                                    </div>
                                                </div>
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Business Name*</label>
                                                    <input 
                                                        name="name" 
                                                        type="text"
                                                        className="form-control"
                                                        // className={`form-control ${errors.title && 'is-invalid'}`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name}
                                                        maxLength={100}
                                                    />
                                                    <p className="errorMessage">
                                                    <ErrorMessage
                                                        className="form-text text-danger"
                                                        name="name"
                                                    />
                                                    </p>
                                                    {/* {(errors.title && touched.title) &&
                                                        <p className="invalid-feedback">{errors.title}</p>
                                                    } */}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Email</label>
                                                    <input 
                                                        name="email" 
                                                        type="email"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                    />
                                                    <p className="errorMessage">
                                                    <ErrorMessage
                                                        className="form-text text-danger"
                                                        name="email"
                                                    />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Phone</label>
                                                    <input 
                                                        name="phone" 
                                                        type="text"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.phone}
                                                        maxLength={15}
                                                    />
                                                    <p className="errorMessage">
                                                    <ErrorMessage
                                                        className="form-text text-danger"
                                                        name="phone"
                                                    />
                                                    </p>
                                                </div>
                                            </div>
                                          
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Website Url</label>
                                                    <input 
                                                        name="website_url" 
                                                        type="text"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.website_url}
                                                    />
                                                    <p className="errorMessage">
                                                    <ErrorMessage
                                                        className="form-text text-danger"
                                                        name="website_url"
                                                    />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Reservation  Url</label>
                                                    <input 
                                                        name="reservation_url" 
                                                        type="text"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.reservation_url}
                                                    />
                                                    <p className="errorMessage">
                                                    <ErrorMessage
                                                        className="form-text text-danger"
                                                        name="reservation_url"
                                                    />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Instagram Url</label>
                                                        <input 
                                                            name="instagram_url"
                                                            type="text"
                                                            className={`form-control ${errors.instagram_url && 'is-invalid'}`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.instagram_url}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="instagram_url"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Facebook Url</label>
                                                        <input 
                                                            name="facebook_url" 
                                                            type="text"
                                                            className={`form-control ${errors.facebook_url && 'is-invalid'}`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.facebook_url}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="facebook_url"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Social Url</label>
                                                        <input 
                                                            name="social_url" 
                                                            type="text"
                                                            className={`form-control ${errors.social_url && 'is-invalid'}`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.social_url}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="social_url"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div> */}
                                            <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>$Price Sign*</label>
                                                        <select 
                                                            className={`form-control ${errors.currency_type && 'is-invalid'}`}
                                                            name="currency_type"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required
                                                        >
                                                            <option value="" selected disabled>Select</option>
                                                            <option value="$" selected={data.currency_type==="$"}>$</option>
                                                            <option value="$$" selected={data.currency_type==="$$"}>$$</option>
                                                            <option value="$$$" selected={data.currency_type==="$$$"}>$$$</option>
                                                            <option value="$$$$" selected={data.currency_type==="$$$$"}>$$$$</option>
                                                           
                                                        
                                                        </select>
                                                    </div>
                                                </div>

                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label>Description</label>
                                                    <textarea
                                                        name="description" 
                                                        type="textarea"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.description}
                                                        style={{ resize: 'vertical', minHeight: '100px' }}
                                                    />
                                                    <p className="errorMessage">
                                                    <ErrorMessage
                                                        className="form-text text-danger"
                                                        name="description"
                                                    />
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label><b>Add Menu</b></label>
                                                </div>
                                                <div className="col-md-4 form-check">
                                                    <input 
                                                        name="menu_type" 
                                                        type="radio"
                                                        value="true"
                                                        className="form-check-input"
                                                        onChange={() => showDescriptionFunction(true)}
                                                        checked={showDesc==true}
                                                    />
                                                    <label className="form-check-label" for="exampleRadios2">
                                                        Link
                                                    </label>
                                                </div>
                                                <div className="col-md-4 form-check">
                                                    <input 
                                                        name="menu_type" 
                                                        type="radio"
                                                        value="false"
                                                        className="form-check-input"
                                                        onChange={() => showDescriptionFunction(false)}
                                                        checked={showDesc==false}
                                                    />
                                                    <label className="form-check-label" for="exampleRadios2">
                                                        PDF
                                                    </label>
                                                </div>
                                            </div>
                                            <br/>
                                            {showDesc &&
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>PDF Link</label>
                                                        <input 
                                                            name="pdf_link" 
                                                            type="text"
                                                            className={`form-control ${errors.pdf_link && 'is-invalid'}`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.pdf_link}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="pdf_link"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div>
                                            }
                                            {!showDesc &&
                                            <>
                                                <div><label for="exampleInputEmail1">Upload menu (PDF format)</label></div>

                                                {renderMenuPDF('resto_menu', <img src='/assets/images/upld.png' className="img-user" />)}
                                                </>
                                            }
                                         
                                            <br />

                                            <label for="exampleInputEmail1">Image (You can select upto 5 Images same time)</label>
                                            <br/>
                                            {restaurant_images()}
                                            {imageError && 
                                                <span className="errorMessage"><b>Required*</b></span>
                                            }

                                            <div className="row margin-main-bar">
                                                <div className="col-md-12">
                                                    <div className="card profile-widget booking">
                                                        <div className="list-group list-group">
                                                            <div className="list-group-item">Open Hours: 
                                                                <span style={{ marginLeft:'10px', marginBottom:'8px', cursor:'pointer'}} onClick={()=> openModal(data)}className="btn btn-primary btn-sm float-right" title="Add">
                                                                    <i className="fa fa-lg fa-plus"></i>
                                                                </span>
                                                                <table class="table table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                        <th scope="col">Title</th>
                                                                        <th scope="col">Start Time</th>
                                                                        <th scope="col">End Time</th>
                                                                        <th scope="col">Is Closed</th>
                                                                        <th scope="col">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {data?.restaurant_open_hours?.length>0  && data.restaurant_open_hours.map((rest_hours,i) => {
                                                                        return(
                                                                            <tr key={i}>
                                                                                <td>{rest_hours.title? rest_hours.title : '--'}</td>
                                                                                <td>{rest_hours.start_time ? rest_hours.start_time : '--'}</td>
                                                                                <td>{rest_hours.end_time ? rest_hours.end_time : '--'}</td>
                                                                                <td>{rest_hours.is_closed==false ? 'Open' : 'Closed'}</td>
                                                                                <td>
                                                                                    <span style={{ marginLeft:'10px', cursor:'pointer'}} className="btn btn-warning btn-sm" title="Edit" onClick={()=> editModal(rest_hours)}>
                                                                                        <i className="fa fa-lg fa-edit"></i>
                                                                                    </span>
                                                                                    <span style={{ marginLeft:'10px', cursor:'pointer'}} className="btn btn-danger btn-sm" title="Delete" onClick={() => {popupDelete(rest_hours.id)}}>
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </span>  
                                                                                </td>                              
                                                                            </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="card-footer float-right col-md-4">
                                            <LoadingButton
                                                type="submit"
                                                className="btn btn-primary btn-lg btn-block"
                                                loading={sending}
                                                disabled={submitting}
                                            >
                                            {!sending ? 'Update Changes' : ''}
                                            </LoadingButton>
                                        </div>
                                </form>
                                )}
                            </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                }
                </section>
        </>
    )
}

const validation = Yup.object().shape({
    name: Yup.string().trim().required("Required*"), 
    // currency_type: Yup.string().trim().required("Required*"), 
    // description: Yup.string().trim().required("Required*"), 
    instagram_url: Yup.string().url('Invalid URL format'),  
    facebook_url: Yup.string().url('Invalid URL format'),  
    website_url: Yup.string().url('Invalid URL format'), 
    social_url: Yup.string().url('Invalid URL format'), 
    reservation_url: Yup.string().url('Invalid URL format'), 
});

const validationHours= Yup.object().shape({
    // title: Yup.string().trim().required("Required*"),  
    // start_time: Yup.string().trim().required("Required*"), 
    // end_time: Yup.string().trim().required("Required*"), 
});



const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
        editHour: state.persistStore.editHour,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const EditForm = reduxForm({
	form: 'edit',
    enableReinitialize:true
})(Edit);

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);