import * as Path from './paths';

//-----------------------auth--------------------//
import SignIn from 'components/views/admin/auth/Login';
import ResetPassword from 'components/views/admin/auth/ResetPassword';
//------------Dashboard--------------------------//
import Dashboard from 'components/views/admin/dashboard/Dashboard';

//------------CMS--------------------------//
import Cms from 'components/views/admin/cms/Cms';
import About from 'components/views/admin/cms/About';
import PrivacyPolicy from 'components/views/admin/cms/PrivacyPolicy';
import Terms from 'components/views/admin/cms/Terms';
import Help from 'components/views/admin/cms/Help';

//------------Profile--------------------------//
import Profile from 'components/views/admin/profile/Profile';
import ChangePassword from 'components/views/admin/profile/ChangePassword';

//------------User--------------------------//
import UserList from 'components/views/admin/users/Listing';
import UserView from 'components/views/admin/users/View';

//------------RestaurantType--------------------------//
import RestaurantTypeList from 'components/views/admin/restaurant_type/Listing';
import RestaurantTypeAddEdit from 'components/views/admin/restaurant_type/AddEdit';
import RestaurantTypeView from 'components/views/admin/restaurant_type/View';

//------------Offer--------------------------//
import OfferList from 'components/views/admin/offers/Listing';
import OfferAdd from 'components/views/admin/offers/Add';
import OfferEdit from 'components/views/admin/offers/Edit';
import OfferView from 'components/views/admin/offers/View';

//------------Offer Type--------------------------//
import OfferTypeList from 'components/views/admin/offer_type/Listing';
import OfferTypeAddEdit from 'components/views/admin/offer_type/AddEdit';
import OfferTypeView from 'components/views/admin/offer_type/View';

//------------ Location--------------------------//
import LocationList from 'components/views/admin/location/Listing';
import LocationAddEdit from 'components/views/admin/location/AddEdit';

//------------Offer Category--------------------------//
import OfferCategoryList from 'components/views/admin/offer_category/Listing';
import OfferCategoryAddEdit from 'components/views/admin/offer_category/AddEdit';
import OfferCategoryView from 'components/views/admin/offer_category/View';

//------------Ads--------------------------//
import AdsList from 'components/views/admin/ads/Listing';
import AdsAdd from 'components/views/admin/ads/Add';

//------------Banner--------------------------//
import BannerList from 'components/views/admin/banners/Listing';
import BannerAdd from 'components/views/admin/banners/Add';


//------------Restaurant--------------------------//
import RestaurantList from 'components/views/admin/restaurant/Listing';
import RestaurantAdd from 'components/views/admin/restaurant/Add';
import RestaurantEdit from 'components/views/admin/restaurant/Edit';
import RestaurantView from 'components/views/admin/restaurant/View';


//------------Notification--------------------------//
import NotificationList from 'components/views/admin/notifications/Listings';

//------------ User Redeem coupon--------------------------//
import CouponList from 'components/views/admin/coupon/Listing';
import CouponView from 'components/views/admin/coupon/View';

//------------Rating--------------------------//
import RatingList from 'components/views/admin/ratings/Listing';
import RatingView from 'components/views/admin/ratings/View';

//------------Redeem Coupon--------------------------//
import RedeemList from 'components/views/admin/redeem_coupon/Listing';
import RedeemView from 'components/views/admin/redeem_coupon/View';

//------------ Tags--------------------------//
import TagList from 'components/views/admin/tag/Listing';
import TagAddEdit from 'components/views/admin/tag/AddEdit';

//------------ Cuisine--------------------------//
import CuisineList from 'components/views/admin/cuisine/Listing';
import CuisineAddEdit from 'components/views/admin/cuisine/AddEdit';


//---------------Page not found--------------//
import NotFound from 'components/NotFound';

const routes = [  
	{
		path: Path.login,
		exact: true,
		auth: false,
		fallback: true,
		component: SignIn,
	},
	/* dashboard routes */
	{
		path: Path.dashboard,
		exact: true,
		auth: true,
		component: Dashboard,
	},
	/* reset password */
	{
		path: Path.reset_password,
		exact: true,
		auth: false,
		component: ResetPassword,
	},
	/* profile routes */
	{
		path: Path.profile,
		exact: true,
		auth: true,
		component: Profile,
	},
	{
		path: Path.change_password,
		exact: true,
		auth: true,
		component: ChangePassword,
	},
	/* cms routes */
	{
		path: Path.cms,
		exact: true,
		auth: false,
		component: Cms,
	},
	{
		path: Path.cms_about,
		exact: true,
		auth: true,
		component: About,
	},
	{
		path: Path.cms_privacy_policy,
		exact: true,
		auth: true,
		component: PrivacyPolicy,
	},
	{
		path: Path.cms_terms,
		exact: true,
		auth: true,
		component: Terms,
	},
	{
		path: Path.help,
		exact: true,
		auth: true,
		component: Help,
	},
	/* users routes */
	{
		path: Path.users,
		exact: true,
		auth: true,
		component: UserList,
	},
	{
		path: Path.userview,
		exact: true,
		auth: true,
		component: UserView,
	},
	{
		path: Path.user_view,
		exact: true,
		auth: true,
		component: UserView,
	},
	/* restaurant type routes */
	{
		path: Path.restaurant_type,
		exact: true,
		auth: true,
		component: RestaurantTypeList,
	},
	{
		path: Path.restaurant_type_form,
		exact: true,
		auth: true,
		component: RestaurantTypeAddEdit,
	},
	{
		path: Path.restaurant_type_form_edit,
		exact: true,
		auth: true,
		component: RestaurantTypeAddEdit,
	},
	{
		path: Path.restaurant_typeview,
		exact: true,
		auth: true,
		component: RestaurantTypeView,
	},
	{
		path: Path.restaurant_type_view,
		exact: true,
		auth: true,
		component: RestaurantTypeView,
	},
	/* offers routes */
	{
		path: Path.offer,
		exact: true,
		auth: true,
		component: OfferList,
	},
	{
		path: Path.offer_list,
		exact: true,
		auth: true,
		component: OfferList,
	},
	{
		path: Path.offer_form,
		exact: true,
		auth: true,
		component: OfferAdd,
	},
	{
		path: Path.offerform,
		exact: true,
		auth: true,
		component: OfferAdd,
	},
	{
		path: Path.offer_form_edit,
		exact: true,
		auth: true,
		component: OfferEdit,
	},
	{
		path: Path.offer_formedit,
		exact: true,
		auth: true,
		component: OfferEdit,
	},
	{
		path: Path.offerview,
		exact: true,
		auth: true,
		component: OfferView,
	},
	{
		path: Path.offer_view,
		exact: true,
		auth: true,
		component: OfferView,
	},
	/* offers types routes */
	{
		path: Path.offer_type,
		exact: true,
		auth: true,
		component: OfferTypeList,
	},
	{
		path: Path.offer_type_form,
		exact: true,
		auth: true,
		component: OfferTypeAddEdit,
	},
	{
		path: Path.offer_type_form_edit,
		exact: true,
		auth: true,
		component: OfferTypeAddEdit,
	},
	{
		path: Path.offer_typeview,
		exact: true,
		auth: true,
		component:OfferTypeView,
	},
	{
		path: Path.offer_type_view,
		exact: true,
		auth: true,
		component: OfferTypeView,
	},

	/* location  routes */
	{
		path: Path.location,
		exact: true,
		auth: true,
		component: LocationList,
	},
	{
		path: Path.location_form,
		exact: true,
		auth: true,
		component: LocationAddEdit,
	},
	{
		path: Path.location_form_edit,
		exact: true,
		auth: true,
		component: LocationAddEdit,
	},
	
	/* offers category routes */
	{
		path: Path.offer_category,
		exact: true,
		auth: true,
		component: OfferCategoryList,
	},
	{
		path: Path.offer_category_form,
		exact: true,
		auth: true,
		component: OfferCategoryAddEdit,
	},
	{
		path: Path.offer_category_form_edit,
		exact: true,
		auth: true,
		component: OfferCategoryAddEdit,
	},
	{
		path: Path.offer_categoryview,
		exact: true,
		auth: true,
		component:OfferCategoryView,
	},
	{
		path: Path.offer_category_view,
		exact: true,
		auth: true,
		component:OfferCategoryView,
	},
	/* ads routes */
	{
		path: Path.ads,
		exact: true,
		auth: true,
		component: AdsList,
	},
	{
		path: Path.ads_form,
		exact: true,
		auth: true,
		component: AdsAdd,
	},
	/* banners routes */
	{
		path: Path.banners,
		exact: true,
		auth: true,
		component: BannerList,
	},
	{
		path: Path.banner_form,
		exact: true,
		auth: true,
		component: BannerAdd,
	},
	/* restaurant routes */
	{
		path: Path.restaurant,
		exact: true,
		auth: true,
		component: RestaurantList,
	},
	{
		path: Path.notification,
		exact: true,
		auth: true,
		component: NotificationList,
	},
	{
		path: Path.restaurant_form,
		exact: true,
		auth: true,
		component: RestaurantAdd,
	},
	{
		path: Path.restaurant_form_edit,
		exact: true,
		auth: true,
		component: RestaurantEdit,
	},
	{
		path: Path.restaurantview,
		exact: true,
		auth: true,
		component: RestaurantView,
	},
	{
		path: Path.restaurant_view,
		exact: true,
		auth: true,
		component: RestaurantView,
	},

	/* redeem coupon routes */
	{
		path: Path.redeem_coupon,
		exact: true,
		auth: true,
		component: CouponList,
	},
	{
		path: Path.redeemcoupon,
		exact: true,
		auth: true,
		component: CouponList,
	},
	{
		path: Path.redeem_coupon_view,
		exact: true,
		auth: true,
		component: CouponView,
	},
	{
		path: Path.redeem_couponview,
		exact: true,
		auth: true,
		component: CouponView,
	},
	/*rating routes */
	{
		path: Path.rating,
		exact: true,
		auth: true,
		component: RatingList,
	},
	{
		path: Path.rating_view,
		exact: true,
		auth: true,
		component: RatingView,
	},
	{
		path: Path.ratingview,
		exact: true,
		auth: true,
		component: RatingView,
	},

	/*redeem routes */
	{
		path: Path.redeemoffer,
		exact: true,
		auth: true,
		component: RedeemList,
	},
	{
		path: Path.redeemoffer_view,
		exact: true,
		auth: true,
		component: RedeemView,
	},
	{
		path: Path.redeemofferview,
		exact: true,
		auth: true,
		component: RedeemView,
	},

	/* tags  routes */
	{
		path: Path.tag,
		exact: true,
		auth: true,
		component: TagList,
	},
	{
		path: Path.tag_form,
		exact: true,
		auth: true,
		component: TagAddEdit,
	},
	{
		path: Path.tag_form_edit,
		exact: true,
		auth: true,
		component: TagAddEdit,
	},

	/* cuisine  routes */
	{
		path: Path.cuisine,
		exact: true,
		auth: true,
		component: CuisineList,
	},
	{
		path: Path.cuisine_form,
		exact: true,
		auth: true,
		component: CuisineAddEdit,
	},
	{
		path: Path.cuisine_form_edit,
		exact: true,
		auth: true,
		component: CuisineAddEdit,
	},

	{
		path: '/',
		exact: false,
		component: NotFound,
	},

];

export default routes;
