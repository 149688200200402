import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link, useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import { Field, reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';

const renderField = ({
    input,
    placeholder,
    type,
    autoComplete,
    disabled,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} disabled={disabled} autoComplete={autoComplete} className={`form-control ${touched && error ? `is-invalid` : ''}`}/>
        
        {touched &&
            (error && <span className="invalid-feedback">{error}</span>)
        }
    </>
)

const View = (props) => {
    //history
    const history = useHistory();

    let editkey = props && props.match.params.id;
    const [sending, setSending] = useState(false);
    const { handleSubmit, submitting} = props;
    const [data, setData] = useState({});
    const MODULE_NAME = 'offer_type_category';

    const initialValues = {
        name: data ? data.name : "",
    };

    //get data
    async function getData() {
        try{
            let query={
                MODULE_NAME,
                id:editkey
            }
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                props.dispatch(actions.persist_store({ editType:res.body}));
                setSending(false);
                setData(res.body);
            });
        }catch(err) {
            setSending(false);
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

       useEffect(() => {
            if(editkey){
                getData();
            }
       }, []);

    return(
        <>
            <Helmet title="Categories" />
            <section className="section">
                <div className="section-header">
                    <h1>Categories Detail</h1>
                </div>
                {!props.loader &&
                <div className="section-body">
                        <div className="row mt-sm-4">
                            <div className="col-12 col-md-12 col-lg-7 offset-md-2">
                                <div className="card">
                                <div className="card">
                                <form>
                                    <div className="card-header">
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-md-12 col-12">
                                                <label>Name*</label>
                                                <Field 
                                                    name="name" 
                                                    component={renderField}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="card-footer float-right col-md-4">
                                        <Link to={Path.offer_type}>
                                            <LoadingButton
                                                type="button"
                                                className="btn btn-primary"
                                                loading={sending}
                                                disabled={submitting}
                                            >
                                            Back
                                            </LoadingButton>
                                        </Link>
                                    </div>
                                </form>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </section>
        </>
    )
}

const initialValues = (values) => {	
	if(values) {
		return {
			...values,
			name: values.name  ? values.name : "",
        }
	}
}

const mapStateToProps = (state) => {
    return{
        initialValues:initialValues(state.persistStore.editType),
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const ViewForm = reduxForm({
	form: 'view',
})(View);

export default connect(mapStateToProps, mapDispatchToProps)(ViewForm);