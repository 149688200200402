import React, {useState, useEffect} from "react";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import * as Path from 'routes/paths';
import { Field, reduxForm , reset} from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';

const renderField = ({
    input,
    placeholder,
    type,
    autoComplete,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} autoComplete={autoComplete} className={`form-control ${touched && error ? `is-invalid` : ''}`}/>
        
        {touched &&
            (error && <span className="invalid-feedback">{error}</span>)
        }
    </>
)
const ResetPassword = (props) => {

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { handleSubmit, submitting} = props;

    const [values, setValues] = useState({
        hash:"",
        newPassword:"",
        confirmPassword:""
    });

    useEffect(() => {
        let isMounted = true;
        async function getData() {
            try{    
                let id = props ? props.match.params.id : '';
                props.dispatch(actions.persist_store({ loader:true }));
                await props.dispatch(AuthService.resetForm(id)).then((res) => {
                    if(isMounted){
                        props.dispatch(actions.persist_store({ loader:false }));
                        setSuccess(false);
                    }
                });
            }catch(err) {
                console.log(err);
                props.dispatch(actions.persist_store({ loader:false }));
                if(err && err.data && err.data.message){
                    setMessage(err.data.message);
                    setSuccess(true);
                   // swal("Oops!", err.data.message, "error");
                } 
            }  
        }
            getData();
            return () => {
                isMounted = false;
            };
    }, []);

    const handleSubmit_ = async (values) => {
        try{
            let data = {
                hash : props ? props.match.params.id : '',
                newPassword : values.newPassword,
                confirmPassword : values.confirmPassword,
            }
            setSending(true);
            props.dispatch(actions.persist_store({ loader:false }));
            await props.dispatch(AuthService.resetPassword(data)).then((res) => {
                setSending(false);
                props.dispatch(actions.persist_store({  loader:true }));
                swal("Success", res.message, "success");
                setValues({
                    newPassword:"",
                    confirmPassword:"",
                });
                props.dispatch(reset('resetPassword'));
                setMessage(res.message);
                setSuccess(true);
            });
        }catch(err) {
            props.dispatch(actions.persist_store({ loader:false }));
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                setMessage(err.data.message);
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    return(
        <>
            <Helmet title="Reset Password" />
            <div classNameName="container mt-5" style={{overflow:'hidden'}}>
                <div className="row">
                <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div className="login-brand">
                    <img src="/../assets/images/logo.png" alt="logo" width="100" className="shadow-light" />
                    </div>

                    <div className="card card-primary">
                    <div className="card-header"><h4>Reset Password</h4></div>

                    <div className="card-body">
                        <form method="POST" onSubmit={handleSubmit(handleSubmit_)} autoComplete="off">
                        <div className="form-group">
                            <label for="email">New Password</label>
                            <Field 
                                name="newPassword" 
                                component={renderField}
                                // placeholder="New Password"
                                type="password" 
                                // autoComplete="new-password"
                            />
                        </div>

                        <div className="form-group">
                            <div className="d-block">
                                <label for="password" className="control-label">Re-enter Password</label>
                            </div>
                            <Field 
                                name="confirmPassword" 
                                component={renderField}
                                // placeholder="Re-enter Password"
                                type="password" 
                                // autoComplete="new-password"
                            /> 
                        </div>

                        <div className="form-group">
                            <LoadingButton
                                type="submit"
                                className="btn btn-primary btn-lg btn-block"
                                loading={sending}
                                disabled={submitting}
                            >
                                {!sending ? <><i className="fa fa-sign-in fa-lg fa-fw"></i>SEND</> : ''}
                            </LoadingButton>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </>

    )
}
const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const validate = (values) => {
    const errors = {}
    //password
    if (!values.newPassword) {
      errors.newPassword = 'Password Required'
    }
    else if (values.newPassword.length < 6) {
      errors.newPassword = 'Must be 6 characters or greater'
    }
   //Confirm Password
    if(!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    }
    return errors
}
  
const ResetPasswordForm = reduxForm({
	form: 'resetPassword',
    enableReinitialize: true,
	validate,
})(ResetPassword);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);