import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import { reduxForm } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';
import ImageUploading from 'react-images-uploading';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from "moment";
import env from 'env.json';

const Add = (props) => {
    //history
    const history = useHistory();
    
    let resto_id = props && props.match.params.resto_id;

    const [sending, setSending] = useState(false);
    const { handleSubmit, submitting} = props;
    const [offerType, setOfferType] = useState([]);

    const todayDate = moment().format('YYYY-MM-DD')
    const endDate = moment().add(1,'days').format('YYYY-MM-DD')
    const [finalEndDate, setFinalEndDate] = useState(endDate);
    const [startDate, setStartDate] = useState("");
    const [newValues, setNewValues] = useState({
    });

    
    const initialValues = {
        description: newValues ? newValues.description : '',
        start_date: newValues ? newValues.start_date : '',
        title: newValues ? newValues.title : '',
        end_date: newValues ? newValues.end_date : '',
        offer_type_id:newValues? newValues.offer_type_id: '',
    };

    const MODULE_NAME = 'restaurant_offer';

    //images
    const [ images, setImages] = useState([]);        
    const maxNumber = 1;

    //images
    const onChange = (imageList) => {
        setImages(imageList);
    };

    //handle change
    const handleChanges = (e) => {
        const { name, value } = e.target;
        // console.log(value,'----ggg-----');
        setNewValues({
            ...newValues,
            [name]: value
        });
        if(name=='start_date'){
            setStartDate(e.target.value)
            let setEnddate = moment(e.target.value).format('YYYY-MM-DD')
            setFinalEndDate(setEnddate)
        }
    };

    //offer types
    async function getOfferType() {
        try{
            let query={
                MODULE_NAME,
                URL_NAME:'all_list',
            }
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                setSending(false);
                setOfferType(res.body);
            });
        }catch(err) {
            setSending(false);
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

       useEffect(() => {
            getOfferType();
       }, []);

       const submitData_ = async (values, { setSubmitting, resetForm }) => {
        try{
            const formData = new FormData(); 
            let query = {
                MODULE_NAME,
                description : values.description,
                title : values.title,
                start_date : values.start_date,
                end_date : values.end_date,
                restaurant_id : resto_id,
                offer_type_id : values.offer_type_id,
            };

            if(images.length > 0 && images[0]['data_url']){
                for ( let key in images ) {
                    if(images[key]['file']){
                        formData.append('image', images[key]['file']);
                    }
                }
                
                formData.append('type', 'image');
                formData.append('folder', 'offers');
                await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
                    let newImg = res.body;
                    query.icon = JSON.stringify(newImg)
                });
            }
            setSending(true);
            await props.dispatch(AuthService.createData(query)).then((res) => {
                setSending(false);
                swal("Success", res.message, "success");
                history.push(`${Path.offer}/${resto_id}`);
                
            });
        }catch(err) {
             console.log(err);
             setSending(false);
              if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }
    

    return(
        <>
            <Helmet title="Offers" />
            <section className="section">
                <div className="section-header">
                    <h1>Add Offers</h1>
                </div>
                {!props.loader &&
                <div className="section-body">
                        <div className="row mt-sm-4">
                            <div className="col-12 col-md-12 col-lg-7 offset-md-2">
                                <div className="card">
                                <Formik
                                    initialValues={initialValues}
                                    enableReinitialize
                                    validationSchema={validation}
                                    onSubmit={submitData_}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="card-header">
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="form-group col-md-12">
                                                        <label>Offer Type</label>
                                                        <Field as="select" class="form-control" name="offer_type_id"  onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.offer_type_id}>
                                                                <option value="" disabled selected>--Select--</option>
                                                                {offerType.length>0 && offerType.map((offer) => {
                                                                    return(
                                                                        <option value={offer.id}>{offer.name}</option>
                                                                    )
                                                                })}
                                                               
                                                        </Field>
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger invalid-feedback"
                                                            name="offer_type_id"
                                                        />
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Title*</label>
                                                        <input 
                                                            name="title" 
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.title}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="title"
                                                        />
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Description*</label>
                                                        <textarea
                                                            name="description" 
                                                            type="textarea"
                                                            className="form-control"
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.description}
                                                            style={{ resize: 'vertical', minHeight: '100px' }}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="description"
                                                        />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-6 col-12">
                                                        <label>Start Date*</label>
                                                        <input 
                                                            name="start_date" 
                                                            type="date"
                                                            min={todayDate}
                                                            className="form-control"
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.start_date}
                                                        />
                                                        { startDate == '' &&
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="start_date"
                                                        />
                                                        </p>
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-6 col-12">
                                                        <label>End Date*</label>
                                                        <input 
                                                            name="end_date" 
                                                            type="date"
                                                            className="form-control"
                                                            min={finalEndDate}
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.end_date}
                                                            disabled={startDate=="" ? true : false}
                                                            placeholder="dd-mm-yyy"
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="end_date"
                                                        />
                                                        </p>
                                                    </div>
                                                </div>
                                                <label for="exampleInputEmail1">Icon</label>
                                                <ImageUploading
                                                    multiple
                                                    value={images}
                                                    onChange={onChange}
                                                    maxNumber={maxNumber}
                                                    dataURLKey="data_url"
                                                    >
                                                    {({
                                                        imageList,
                                                        onImageUpload,
                                                        // onImageRemoveAll,
                                                        onImageUpdate,
                                                        onImageRemove,
                                                        isDragging,
                                                        dragProps,
                                                    }) => (
                                                        // write your building UI
                                                        <>
                                                        <div className="form-row " style={isDragging ? { color: 'red' } : undefined}
                                                            onClick={onImageUpload}
                                                            {...dragProps}>
                                                            <img src="/assets/img/news/img01.jpg" onClick={() => onImageRemove(0)} style={{width:'100px', height:'100px', margin:'12px'}} />
                                                        </div>
                                                        <div className="row">
                                                            {images.length>0 && images.map((image, index) => {
                                                                let imgData;
                                                                if(image['data_url']){
                                                                    imgData = image['data_url'];
                                                                } else{
                                                                    imgData = env.SERVER_URL+image;
                                                                }
                                                                return(
                                                                <div className="image-item" key={index}>
                                                                    <div className="close-img-item" onClick={() => onImageRemove(index)}>
                                                                        <i className="fa fa-times" aria-hidden="true"></i>
                                                                    </div>
                                                                    <img src={imgData} alt="" style={{width:'100px', height:'100px', marginLeft:'20px'}}/>
                                                                </div>
                                                                )
                                                            })}
                                                            
                                                        </div>
                                                        </>
                                                    )}
                                                </ImageUploading> 
                                            </div>
                                            <div className="card-footer float-right col-md-4">
                                                <LoadingButton
                                                    type="submit"
                                                    className="btn btn-primary btn-lg btn-block"
                                                    loading={sending}
                                                    disabled={submitting}
                                                >
                                                {!sending ? 'Save Changes' : ''}
                                                </LoadingButton>
                                            </div>
                                    </form>
                                    )}
                                </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </section>
        </>
    )
}

const validation = Yup.object().shape({
    title: Yup.string().trim().required("Required*"),  
    description: Yup.string().trim().required("Required*"),  
    offer_type_id: Yup.string().trim().required("Required*"),   
    start_date: Yup.string().trim().required("Required*"),  
    end_date: Yup.string().trim().required("Required*"),  
});



const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const AddForm = reduxForm({
	form: 'add',
    enableReinitialize:true
})(Add);

export default connect(mapStateToProps, mapDispatchToProps)(AddForm);