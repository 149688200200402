import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link, useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import { Field, reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';
import { Formik  } from 'formik';
import env from 'env.json';
import moment from "moment";

const View = (props) => {
    const MODULE_NAME = 'restaurant';
    //history
    const history = useHistory();

    let editkey = props && props.match.params.id;
    const [sending, setSending] = useState(false);
    const [data, setData] = useState({});

     //get data
     async function getData() {
        try{
            let query={
                MODULE_NAME,
                id:editkey
            }
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                setSending(false);
                setData(res.body);
            });
        }catch(err) {
            setSending(false);
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

       useEffect(() => {
            if(editkey){
                getData();
            }
       }, []);


    

    return(
        <>
            <Helmet title="Business" />
            <section className="section">
                <div className="section-header">
                    <h1>Business</h1>
                </div>
                {!props.loader &&
                <div className="section-body">
                    <div className="row mt-sm-4">
                        <div className="col-md-12">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Business Info :</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {data && 
                    <>
                    <div className="row margin-main-bar">
                        <div className="col-md-8">
                            <div className="card profile-widget booking">
                                <ul className="list-group list-group">
                                    <li className="list-group-item"><b>Offer Type</b> 
                                        <span className="float-right">{data && data.offer_type ? data.offer_type?.name : ''}</span>
                                    </li>
                                    <li className="list-group-item"><b>Offer Category</b> 
                                        <span className="float-right">{data && data.offer_type_category ? data.offer_type_category?.name : ''}</span>
                                    </li>
                                    <li className="list-group-item"><b>Name</b> 
                                        <span className="float-right">{data.name}</span>
                                    </li>
                                    {data.email &&
                                    <li className="list-group-item"><b>Email</b> 
                                        <span className="float-right">{data.email}</span>
                                    </li>
                                    }
                                    {data.phone &&
                                    <li className="list-group-item"><b>Phone</b> 
                                        <span className="float-right">{data.phone}</span>
                                    </li>
                                    }
                                    <li className="list-group-item"><b>Address</b> 
                                        <span className="float-right">{data.location}</span>
                                    </li>
                                    {/* <li className="list-group-item"><b>Price</b> 
                                        <span className="float-right">${data.price}</span>
                                    </li> */}
                                  
                                    {data.menu &&
                                    <li className="list-group-item"><b>Menu:</b> 
                                    <>
                                    {data.menu_type==1 &&
                                        <span className="float-right"><a href={data.menu ? env.SERVER_URL+data.menu : '#'} target="_blank">
                                        <p> Click here </p>
                                        </a></span>
                                    }
                                    {data.menu_type==2 &&
                                        <span className="float-right"><a href={data.menu} target="_blank">
                                        <p> Click here </p>
                                        </a></span>
                                    }
                                    </>
                                    </li>
                                    }
                                    <li className="list-group-item"><b>Status</b> 
                                    <span className="float-right"><div className={`badge ${data.status=='1' ? 'badge-success' : 'badge-danger'}`}>{data.status=='1' ? 'Active' : 'Inactive'}</div></span>
                                    </li>
                                    <li className="list-group-item"><b>Redeemed Coupon Count</b> 
                                        <span className="float-right">{data.redeemed_count}</span>
                                    </li>
                                    <li className="list-group-item"><b>$Sign</b> 
                                        <span className="float-right">{data.currency_type}</span>
                                    </li>
                                    <li className="list-group-item"><b>Rating</b> 
                                        <span className="float-right">{data?.total_rating!=null ? parseFloat(data?.total_rating).toFixed(1) : 0} <i className="fa fa-star"></i></span>
                                    </li>
                                    <li className="list-group-item"><b>Created Date</b> 
                                        <span className="float-right">{moment(data.created_at).format('YYYY-MM-DD')}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                     
                    </div>
                    {data.website_url &&
                    <div className="row margin-main-bar">
                        <div className="col-md-12">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Website Url :</div>
                                    <p><a href={data.website_url} target="_blank">{data.website_url}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {data.reservation_url &&
                    <div className="row margin-main-bar">
                        <div className="col-md-12">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Reservation Url :</div>
                                    <p><a href={data.reservation_url} target="_blank">{data.reservation_url}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {data.instagram_url&&
                    <div className="row margin-main-bar">
                        <div className="col-md-12">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Instagram Url :</div>
                                    <p><a href={data.instagram_url} target="_blank">{data.instagram_url}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                      {data.facebook_url &&
                    <div className="row margin-main-bar">
                        <div className="col-md-12">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Facebook Url :</div>
                                    <p><a href={data.facebook_url} target="_blank">{data.facebook_url}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                     {data.social_url &&
                    <div className="row margin-main-bar">
                        <div className="col-md-12">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Social Url :</div>
                                    <p><a href={data.social_url} target="_blank">{data.social_url}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="row margin-main-bar">
                        <div className="col-md-12">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Description :</div>
                                    <p>{data.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {data.restaurant_cuisines?.length>0 && 
                        <div className="row margin-main-bar">
                            <div className="col-md-12">
                                <div className="card profile-widget booking">
                                    <div className="profile-widget-description">
                                        <div className="profile-widget-name booking">Type/Cuisine :</div>
                                        <div className="row">
                                        {data?.restaurant_cuisines?.length>0  && data.restaurant_cuisines.map((cuisine,i) => {
                                            return(
                                                <div className="col-md-4">
                                                    <p>{cuisine?.cuisine?.name}</p>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                     {data.restaurant_tags?.length>0 && 
                        <div className="row margin-main-bar">
                            <div className="col-md-12">
                                <div className="card profile-widget booking">
                                    <div className="profile-widget-description">
                                        <div className="profile-widget-name booking">Tags :</div>
                                        <div className="row">
                                        {data?.restaurant_tags?.length>0  && data.restaurant_tags.map((tags,i) => {
                                            return(
                                                <div className="col-md-4">
                                                    <p>{tags?.tag?.name}</p>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row margin-main-bar">
                        <div className="col-md-6">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Business Images :</div><br/>
                                    {data?.restaurant_images?.length>0 && data.restaurant_images.map((resto_image, i) => {
                                        return(
                                            <a href={resto_image.image ? env.SERVER_URL+resto_image.image : ''} target="_blank"><img src={resto_image && resto_image.image_thumb ? env.SERVER_URL+resto_image.image_thumb : '/assets/img/avatar/avatar-1.png'} className="img-box-details" /></a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {data?.restaurant_open_hours?.length>0 && 
                            <div className="col-md-6">
                                <div className="card profile-widget booking">
                                    <div className="list-group list-group">
                                        <div className="list-group-item"><b>Open Hours:</b>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Start Time</th>
                                                    <th scope="col">End Time</th>
                                                    <th scope="col">Is Closed</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.restaurant_open_hours?.length>0  && data.restaurant_open_hours.map((open_hours,i) => {
                                                        // let openHour =  moment(open_hours.start_time, ["HH:mm"]).format("hh:mm");
                                                        // let closeHour =  moment(open_hours.end_time, ["HH:mm"]).format("hh:mm");
                                                        return(
                                                            <tr key={i}>
                                                                <td>{open_hours.title ? open_hours.title : '--'}</td>
                                                                <td>{open_hours.start_time ? open_hours.start_time : '--'}</td>
                                                                <td>{open_hours.end_time ? open_hours.end_time : '--'}</td>
                                                                <td>{open_hours.is_closed==false ? 'Open' : 'Closed'}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }  
                    </div>
                    </>
                    }
                     <div className="float-right">
                        <Link to={`${Path.restaurant}`}>
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg"
                            >
                        Back
                        </button>
                        </Link>
                    </div>
                    <br />
                </div>
                }
             </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const ViewForm = reduxForm({
	form: 'view',
})(View);

export default connect(mapStateToProps, mapDispatchToProps)(ViewForm);