import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';


const Cms = (props) => {

    const [sending, setSending] = useState(false);
    const { handleSubmit, submitting} = props;
    const [data, setData] = useState({});
    const MODULE_NAME = 'cms';

     //values
     const [ values, setValues ] = useState({
        slug:"",
        description:"",
    });
   
    let slug = props && props.match.params.slug;
    //get content
    const getContent = async () => {
        try{
            let query = {
                slug: slug,
                MODULE_NAME
            };
            console.log(query)
            await props.dispatch(AuthService.getContentApp(query)).then((res) => {
                setData(res.body);
            });
        }catch(err) {
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    useEffect(() => {
        getContent();  
    }, []);


    return(
        <>
            {data ?  <div dangerouslySetInnerHTML={{__html: data.description}} /> : ""}
            
        </>
    )
}
const mapStateToProps = (state) => {
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cms);

