import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link, useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import { Field, reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';
import { Formik  } from 'formik';
import env from 'env.json';
import moment from "moment";


const View = (props) => {
    //history
    const history = useHistory();

    let editkey = props && props.match.params.id;
    let user_id = props && props.match.params.user_id;
    const [sending, setSending] = useState(false);
    const { handleSubmit, submitting} = props;
    const [data, setData] = useState({});

    const MODULE_NAME = 'redeem_coupon';

    //get data
    async function getData() {
        try{
            let query={
                MODULE_NAME,
                id:editkey
            }
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                setSending(false);
                setData(res.body);
            });
        }catch(err) {
            setSending(false);
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

       useEffect(() => {
            if(editkey){
                getData();
            }
       }, []);

    return(
        <>
            <Helmet title="Redeemed Coupon" />
            <section className="section">
                <div className="section-header">
                    <h1>Redeemed Coupon</h1>
                </div>
                {!props.loader &&
               <div className="section-body">
               <div className="row mt-sm-4">
                   <div className="col-md-12">
                       <div className="card profile-widget booking">
                           <div className="profile-widget-description">
                               <div className="profile-widget-name booking">Offer Info :</div>
                           </div>
                       </div>
                   </div>
               </div>
               {data && 
               <>
               <div className="row margin-main-bar">
                   <div className="col-md-12">
                       <div className="card profile-widget booking">
                           <div className="profile-widget-description">
                               <div className="profile-widget-name booking">Offer :</div>
                               <p>{data?.restaurant_offer?.description}</p>
                           </div>
                       </div>
                   </div>
               </div>
               <div className="row margin-main-bar">
                   <div className="col-md-6">
                       <div className="card profile-widget booking">
                           <ul className="list-group list-group">
                               <li className="list-group-item"><b>Offer Type</b> 
                                   <span className="float-right">{data && data?.restaurant_offer?.offer_type ? data?.restaurant_offer?.offer_type.name : ''}</span>
                               </li>
                               <li className="list-group-item"><b>Start Date</b> 
                                   <span className="float-right">{data?.restaurant_offer?.start_date}</span>
                               </li>
                               <li className="list-group-item"><b>End Date</b> 
                                   <span className="float-right">{data?.restaurant_offer?.end_date}</span>
                               </li>
                               <li className="list-group-item"><b>Redeemed Coupon Date</b> 
                                   <span className="float-right">{moment(data?.created_at).format('YYYY-MM-DD')}</span>
                               </li>
                           </ul>
                       </div>
                   </div>
                   {data?.restaurant_offer?.icon &&
                   <div className="col-md-6">
                       <div className="card profile-widget booking">
                           <div className="profile-widget-description">
                               <div className="profile-widget-name booking">Icon :</div><br/>
                                   <a href={data?.restaurant_offer?.icon ? env.SERVER_URL+data.restaurant_offer?.icon : ''} target="_blank"><img src={data && data?.restaurant_offer?.icon ? env.SERVER_URL+data?.restaurant_offer?.icon : '/assets/img/avatar/avatar-1.png'} className="img-box-details" /></a>
                           </div>
                       </div>
                   </div>
                }
               </div>
               {data?.signature &&
               <div className="row mt-sm-4">
                   <div className="col-md-6">
                       <div className="card profile-widget booking">
                           <div className="profile-widget-description">
                               <div className="profile-widget-name booking">Digital Signature :</div><br/>
                                   <a href={data?.signature ? env.SERVER_URL+data?.signature : ''} target="_blank"><img src={data && data?.signature ? env.SERVER_URL+data?.signature : '/assets/img/avatar/avatar-1.png'} className="img-box-details" /></a>
                           </div>
                       </div>
                   </div>
               </div>
               }
               <div className="row mt-sm-4">
                   <div className="col-md-12">
                       <div className="card profile-widget booking">
                           <div className="profile-widget-description">
                               <div className="profile-widget-name booking">Business Info :</div>
                           </div>
                       </div>
                   </div>
               </div>
               <div className="row margin-main-bar">
                   <div className="col-md-8">
                       <div className="card profile-widget booking">
                           <ul className="list-group list-group">
                               <li className="list-group-item"><b>Name</b> 
                                   <span className="float-right">{data && data?.restaurant ? data?.restaurant.name : ''}</span>
                               </li>
                               <li className="list-group-item"><b>Offer Type</b> 
                                   <span className="float-right">{data?.restaurant_offer?.offer_type?.name}</span>
                               </li>
                               {data?.restaurant?.email &&
                               <li className="list-group-item"><b>Email</b> 
                                   <span className="float-right">{data?.restaurant?.email}</span>
                               </li>
                                }
                                {data?.restaurant?.phone &&
                               <li className="list-group-item"><b>Phone</b> 
                                   <span className="float-right">{data?.restaurant?.phone}</span>
                               </li>
                               }
                               <li className="list-group-item"><b>Address</b> 
                                   <span className="float-right">{data?.restaurant?.location}</span>
                               </li>
                               <li className="list-group-item"><b>$Sign</b> 
                                    <span className="float-right">{data?.restaurant?.currency_type}</span>
                                </li>
                               <li className="list-group-item"><b>Created Date</b> 
                                    <span className="float-right">{moment(data?.restaurant?.created_at).format('YYYY-MM-DD')}</span>
                                </li>
                           </ul>
                       </div>
                   </div>
               </div>
               {data?.restaurant?.website_url &&
               <div className="row margin-main-bar">
                    <div className="col-md-8">
                        <div className="card profile-widget booking">
                            <div className="profile-widget-description">
                                <div className="profile-widget-name booking">Website Url :</div><br/>
                               <p><a href={data?.restaurant?.website_url} target="_blank">{data?.restaurant?.website_url}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                }
               {data?.restaurant?.reservation_url &&
               <div className="row margin-main-bar">
                    <div className="col-md-8">
                        <div className="card profile-widget booking">
                            <div className="profile-widget-description">
                                <div className="profile-widget-name booking">Reservation Url :</div><br/>
                               <p><a href={data?.restaurant?.reservation_url} target="_blank">{data?.restaurant?.reservation_url}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                }
               <div className="row margin-main-bar">
                    <div className="col-md-8">
                        <div className="card profile-widget booking">
                            <div className="profile-widget-description">
                                <div className="profile-widget-name booking">Business Images :</div><br/>
                                {data?.restaurant?.restaurant_images?.length>0 && data.restaurant?.restaurant_images.map((resto_image, i) => {
                                    return(
                                        <a href={resto_image.image ? env.SERVER_URL+resto_image.image : ''} target="_blank"><img src={resto_image && resto_image.image_thumb ? env.SERVER_URL+resto_image.image_thumb : '/assets/img/avatar/avatar-1.png'} className="img-box-details" /></a>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

               <div className="row mt-sm-4">
                   <div className="col-md-12">
                       <div className="card profile-widget booking">
                           <div className="profile-widget-description">
                               <div className="profile-widget-name booking">Offer Redeemed User Info :</div>
                           </div>
                       </div>
                   </div>
               </div>
               <div className="row margin-main-bar">
                   <div className="col-md-6">
                       <div className="card profile-widget booking">
                           <ul className="list-group list-group">
                               <li className="list-group-item"><b>Name</b> 
                                   <span className="float-right">{data && data?.user ? data?.user.first_name+''+data?.user.last_name : ''}</span>
                               </li>
                               <li className="list-group-item"><b>Contact</b> 
                                   <span className="float-right">{data?.user?.country_code} {data?.user?.phone}</span>
                               </li>
                           </ul>
                       </div>
                   </div>

                   <div className="col-md-6">
                       <div className="card profile-widget booking">
                           <div className="profile-widget-description">
                               <div className="profile-widget-name booking">Image :</div><br/>
                                   <a href={data?.user?.image ? env.SERVER_URL+data.user?.image : ''} target="_blank"><img src={data && data?.user?.image ? env.SERVER_URL+data?.user?.image : '/assets/img/avatar/avatar-1.png'} className="img-box-details" /></a>
                           </div>
                       </div>
                   </div>
                   
               </div>
               </>
               }
               <div className="float-right">
                   <Link to={Path.redeemoffer}>
                       <button
                           type="submit"
                           className="btn btn-primary btn-lg"
                       >
                   Back 
                   </button>
                   </Link>
               </div>
               <br />
           </div>
                }
                </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const ViewForm = reduxForm({
	form: 'view',
})(View);

export default connect(mapStateToProps, mapDispatchToProps)(ViewForm);