import * as ActionTypes from 'store/action-types';

export function authLogin(payload) {
  return {
    type: ActionTypes.AUTH_LOGIN,
    payload,
  };
}

export function authCheck() {
    return {
      type: ActionTypes.AUTH_CHECK,
    };
}

export function authLogout() {
  return {
    type: ActionTypes.AUTH_LOGOUT,
  };
}

export function profile(payload) {
  return {
    type: ActionTypes.PROFILE,
    payload
  };
}


export function updateProfile(payload) {
  return {
    type: ActionTypes.UPDATE_PROFILE,
    payload
  };
}

export function fileUpload(payload) {
  return {
    type: ActionTypes.FILE_UPLOAD,
    payload
  };
}


export function updatePassword(payload) {
  return {
    type: ActionTypes.UPDATE_PASSWORD,
    payload
  };
}
export function resetPassword(payload) {
  return {
    type: ActionTypes.RESET_PASSWORD,
    payload
  };
}
export function resetForm(payload) {
  return {
    type: ActionTypes.RESET_FORM,
    payload
  };
}

export function getList(payload) {
  return {
    type: ActionTypes.GET_LIST,
    payload
  };
}

export function getAllList(payload) {
  return {
    type: ActionTypes.GET_ALL_LIST,
    payload
  };
}

export function getDetail(payload) {
  return {
    type: ActionTypes.GET_DETAIL,
    payload
  };
}

export function createData(payload) {
  return {
    type: ActionTypes.CREATE_DATA,
    payload
  };
}

export function updateData(payload) {
  return {
    type: ActionTypes.UPDATE_DATA,
    payload
  };
}

export function updateStatus(payload) {
  return {
    type: ActionTypes.UPDATE_STATUS,
    payload
  };
}

export function deleteData(payload) {
  return {
    type: ActionTypes.DELETE_DATA,
    payload
  };
}

export function getContent(payload) {
  return {
    type: ActionTypes.GET_CONTENT,
    payload
  };
}
export function getContentApp(payload) {
  return {
    type: ActionTypes.GET_CONTENT_APP,
    payload
  };
}

export function updateContent(payload) {
  return {
    type: ActionTypes.UPDATE_CONTENT,
    payload
  };
}

export function dashboardCount(payload) {
  return {
    type: ActionTypes.DASHBOARD_COUNT,
    payload
  };
}

export function addUpdateSlot(payload) {
  return {
    type: ActionTypes.ADD_UPDATE_SLOT,
    payload
  };
}

export function generateExcel(payload) {
  return {
    type: ActionTypes.GENERATE_EXCEL,
    payload
  };
}

export function createOtherData(payload) {
  return {
    type: ActionTypes.CREATE_OTHER_DATA,
    payload
  };
}

export function getOtherData(payload) {
  return {
    type: ActionTypes.GET_OTHER_DATA,
    payload
  };
}

export function deleteOthers(payload) {
  return {
    type: ActionTypes.DELETE_OTHERS,
    payload
  };
}

export function getOtherList(payload) {
  return {
    type: ActionTypes.GET_OTHER_LIST,
    payload
  };
}

export function persist_store(payload) {
    return {
      type: ActionTypes.PERSIST_STORE,
        payload
    };
}