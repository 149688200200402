import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import { List } from 'react-content-loader';
import AuthService from 'services';
import swal from 'sweetalert';
import * as actions from 'store/actions';
//countUp
import CountUp from 'react-countup';

const Dashboard = (props) => {
    const [data, setData] = useState({});

    async function getData() {
        try{
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.dashboardCount()).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                setData(res.body);
            });
        }catch(err) {
            props.dispatch(actions.persist_store({ loader:false}));
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    };

    useEffect(() => {
        getData();
     }, []);

    return(
        <>
            <Helmet title="Dashboard" />
            <section className="section">
                <div className="section-header">
                    <h1>Dashboard</h1>
                </div>
                {!props.loader && data &&
                <div className="section-body">
                    <div className="row">
                        
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.users}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-primary">
                                    <i className="far fa-user"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>Users</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.user ? data.user : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.restaurant_type}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-success">
                                    <i className="far fa-newspaper"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>Restaurant Types</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.restaurant_types ? data.restaurant_types : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div> */}
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.offer_type}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-danger">
                                    <i className="fas fa-th-large"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>Offer Types</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.offer_types ? data.offer_types : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.offer_category}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-warning">
                                    <i className="fas fa-th-large"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>Offers Category</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.offer_type_categories ? data.offer_type_categories : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.location}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-primary">
                                    <i className="fas fa-th-large"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>Location</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.locations ? data.locations : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.ads}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-success">
                                    <i className="fas fa-th-large"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>Ads</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.admin_ads ? data.admin_ads : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.banners}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-primary">
                                    <i className="fas fa-th"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>Banners</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.banners ? data.banners : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.restaurant}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-success">
                                    <i className="fas fa-th"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>Businesses</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.restaurants ? data.restaurants : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.cms_about}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-warning">
                                    <i className="far fa-file"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>CMS</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.cms ? data.cms : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.rating}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-primary">
                                    <i className="far fa-file"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>Rating</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.ratings ? data.ratings : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <Link to={Path.redeemoffer}>
                                <div className="card card-statistic-1">
                                    <div className="card-icon bg-danger">
                                    <i className="far fa-file"></i>
                                    </div>
                                    <div className="card-wrap">
                                    <div className="card-header">
                                        <h4>Redeemed Coupon</h4>
                                    </div>
                                    <div className="card-body">
                                        <CountUp start={0} end={data.redeem_coupons ? data.redeem_coupons : 0} duration={3}/>
                                    </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        
                    </div>
                </div>
                }
                </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader:state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);