import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import env from 'env.json';
import { Field, reduxForm } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';
import ImageUploading from 'react-images-uploading';
import { Formik  } from 'formik';
import * as Yup from 'yup';



const Profile = (props) => {
    const [sending, setSending] = useState(false);
    const [data, setData] = useState({});
    const { handleSubmit, submitting} = props;
    

    const initialValues = {
        first_name: data ? data.first_name : "",
        email: data ? data.email : "",
    };

    //images
    const [ images, setImages] = useState([]);    
    const maxNumber = 1;

    //images
    const onChange = (imageList) => {
        setImages(imageList);
    };

   
     //get data
     async function getData() {
         try{
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.profile()).then((res) => {
                setSending(false);
                props.dispatch(actions.persist_store({ loader:false}));
                setData(res.body);
                let newImg = [];
                newImg.push(res.body.image);
                setImages(newImg);
            });
  
         }catch(err) {
            setSending(false);
            props.dispatch(actions.persist_store({ loader:false}));
            console.log(err);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
         }
    }

    useEffect(() => {
        getData();
    }, []);

    // const updateProfile = async (values) => {
    const updateProfile = async (values, { setSubmitting, resetForm }) => {
        try{
            const formData = new FormData(); 
            let query = {
                first_name : values.first_name,
                // image: images.length > 0 && images[0]['data_url'] ? [...images] : ''
            };

            if(images.length > 0 && images[0]['data_url']){
                for ( let key in images ) {
                    if(images[key]['file']){
                        formData.append('image', images[key]['file']);
                    }
                }
                
                formData.append('type', 'image');
                formData.append('folder', 'users');
                await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
                    let newImg = res.body;
                    query.image = JSON.stringify(newImg)
                });
            }
            setSending(true);
            await props.dispatch(AuthService.updateProfile(query)).then((res) => {
                setSending(false);
                props.dispatch(actions.persist_store({ profile:res.body}));
                swal("Success", res.message, "success");
                getData();
                
            });
        }catch(err) {
             console.log(err);
             setSending(false);
              if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    return(
        <>
            <Helmet title="Profile" />
            <section className="section">
                <div className="section-header">
                    <h1>Profile</h1>
                </div>
               
                {!props.loader &&
                <div className="section-body">
                        <div className="row mt-sm-4">
                            <div className="col-12 col-md-12 col-lg-7 offset-md-2">
                                <div className="card">
                                <Formik
                                    initialValues={initialValues}
                                    enableReinitialize
                                    validationSchema={validation}
                                    onSubmit={updateProfile}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="card-header">
                                                <h4>Edit Profile</h4>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Name</label>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${errors.first_name && 'is-invalid'}`}
                                                            name="first_name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.first_name}
                                                        />
                                                        {(errors.first_name && touched.first_name) &&
                                                            <p className="invalid-feedback">{errors.first_name}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Email</label>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${errors.email && 'is-invalid'}`}
                                                            name="email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                            disabled={true}
                                                        />
                                                        {(errors.email && touched.email) &&
                                                            <p className="invalid-feedback">{errors.email}</p>
                                                        }
                                                    </div>
                                                </div>
                                                    <label for="exampleInputEmail1">Image</label>
                                                    <ImageUploading
                                                        multiple
                                                        value={images}
                                                        onChange={onChange}
                                                        maxNumber={maxNumber}
                                                        dataURLKey="data_url"
                                                        >
                                                        {({
                                                            imageList,
                                                            onImageUpload,
                                                            // onImageRemoveAll,
                                                            onImageUpdate,
                                                            onImageRemove,
                                                            isDragging,
                                                            dragProps,
                                                        }) => (
                                                            // write your building UI
                                                            <>
                                                            <div className="form-row " style={isDragging ? { color: 'red' } : undefined}
                                                                onClick={onImageUpload}
                                                                {...dragProps}>
                                                                <img src="/assets/img/news/img01.jpg" onClick={() => onImageRemove(0)} style={{width:'100px', height:'100px', margin:'12px'}} />
                                                            </div>
                                                            <div className="row">
                                                                {images.length>0 && images.map((image, index) => {
                                                                    let imgData;
                                                                    if(image['data_url']){
                                                                        imgData = image['data_url'];
                                                                    } else{
                                                                        imgData = image ? env.SERVER_URL+image : '';
                                                                    }
                                                                    return(
                                                                    <>
                                                                    {image &&
                                                                        <div className="image-item" key={index}>
                                                                            <div className="close-img-item" onClick={() => onImageRemove(index)}>
                                                                                <i className="fa fa-times" aria-hidden="true"></i>
                                                                            </div>
                                                                            <img src={imgData} alt="" style={{width:'100px', height:'100px', marginLeft:'20px'}}/>
                                                                        </div>
                                                                    }
                                                                    </>
                                                                    )
                                                                })}
                                                                
                                                            </div>
                                                            </>
                                                        )}
                                                    </ImageUploading> 
                                                </div>
                                            <div className="card-footer float-right col-md-4">
                                                <LoadingButton
                                                    type="submit"
                                                    className="btn btn-primary btn-lg btn-block"
                                                    loading={sending}
                                                    disabled={submitting}
                                                >
                                                {!sending ? "Save Changes" : ''}
                                                </LoadingButton>
                                            </div>
                                        </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </section>
        </>
    )
}

const validation = Yup.object().shape({
    first_name: Yup.string().trim().required("Required*"),  
});

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        profile:state.persistStore.profile,
        loader:state.persistStore.loader
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const ProfileForm = reduxForm({
	form: 'profile',
})(Profile);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);