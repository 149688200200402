import Http from 'Http';
import axios from 'axios';
import * as action from 'store/actions';

let adminApiPath = 'admin_api';
let apiPath = 'common_api';
let appApi = 'app_api';
//login
export function login(credentials) {
    return (dispatch) => new Promise((resolve, reject) => {
      Http.post(`${adminApiPath}/auth/login`, credentials)
        .then((res) => {
          dispatch(action.authLogin(res.data));
          return resolve(res.data);
        })
        .catch((err) => {
          const { status, data } = err.response;
          const res = {
            status,
            data,
          };
          return reject(res);
        });
    });
}

//logout
export function logout(credentials) {
  // console.log(credentials, "credentials")
  // return
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${adminApiPath}/auth/logout`, credentials)
      .then((res) => {
        dispatch(action.authLogout(res.data));
        return resolve(res.data);
      })
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//get profile
export function profile() {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.get(`${adminApiPath}/auth/profile`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//update profile
export function updateProfile(values) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${adminApiPath}/auth/edit_profile`, values)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//update password
export function updatePassword(values) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${adminApiPath}/auth/change_password`, values)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//reset password
export function resetPassword(values) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${appApi}/reset_password`, values)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

export function resetForm(values) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.get(`${appApi}/reset_password_form/`+values)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//upload file
export function fileUpload(values) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${apiPath}/file_upload`, values)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//user 
//list
export function getList(value) {
  let page = value.page;
  let sort = value.sortby;
  let search = value.search ? value.search : "";
  let id = value.resto_id ? value.resto_id  : value.user_id ? value.user_id : '';
  let OTHET_URL = value.URL_NAME? value.URL_NAME : 'list'
  return (dispatch) => new Promise((resolve, reject) => {
    Http.get(`${adminApiPath}/${value.MODULE_NAME}/${OTHET_URL}?page=`+page+`&search_string=`+search+`&sort=`+sort+`&id=`+id)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

export function getAllList(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.get(`${adminApiPath}/${value.MODULE_NAME}/all_list`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}


export function getOtherList(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.get(`${adminApiPath}/${value.MODULE_NAME}/${value.URL_NAME}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//view
export function getDetail(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    let Other_NAME = value.URL_NAME ? value.URL_NAME : 'view'
    Http.get(`${adminApiPath}/${value.MODULE_NAME}/${Other_NAME}/`+value.id)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//add
export function createData(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    // console.log(value);
    let OTHER_NAME = value.URL_NAME ? value.URL_NAME : 'add'
    Http.post(`${adminApiPath}/${value.MODULE_NAME}/${OTHER_NAME}`, value)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//createOtherData
export function createOtherData(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    // console.log(value);
    Http.post(`${adminApiPath}/${value.MODULE_NAME}/${value.API_NAME}`, value)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

export function getOtherData(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    // console.log(value);
    Http.get(`${adminApiPath}/${value.MODULE_NAME}/${value.API_NAME}/${value}`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//update
export function updateData(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${adminApiPath}/${value.MODULE_NAME}/edit`, value)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//update status
export function updateStatus(value) {
  let id = value.id;
  let status = value.status ? value.status : '';
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${adminApiPath}/${value.MODULE_NAME}/update_status/`+id+`/`+status)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}



export function updateNotify(value) {
  let id = value.id;
  let status = value.status ? value.status : '';
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${adminApiPath}/${value.MODULE_NAME}/update_status/`+id+`/`+status+`?notify=yes`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//delete
export function deleteData(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.delete(`${adminApiPath}/${value.MODULE_NAME}/delete/`+value.id)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//dashboard
export function dashboardCount() {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.get(`${adminApiPath}/dashboard/list`)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//cms
export function getContent(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.get(`${adminApiPath}/${value.MODULE_NAME}/view/`+value.slug)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

export function getContentApp(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.get(`${apiPath}/${value.MODULE_NAME}/`+value.slug)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}


export function updateContent(values) {
  console.log(values, "values");
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${adminApiPath}/cms/edit/`+values.slug, values)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//addUpdateSlot
export function addUpdateSlot(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${adminApiPath}/${value.MODULE_NAME}/add_edit_hours`, value)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}

//deleted
export function deleteOthers(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.delete(`${adminApiPath}/${value.MODULE_NAME}/${value.URL_NAME}/`+value.hours_id)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}


// generateExcel
export function generateExcel(value) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(`${adminApiPath}/${value.MODULE_NAME}/add_edit_slot`, value)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, data } = err.response;
        const res = {
          status,
          data,
        };
        return reject(res);
      });
  });
}
