import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link, useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import { Field, reduxForm } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as actions from 'store/actions';
import classNames from 'classnames';
const renderckEditor = ({
    input,
    label,
    placeholder,
    type,
    meta: { touched, error, warning }
}) => (
    <>
        <CKEditor
                data={input.value}
                editor={ ClassicEditor }
                config={{
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ] 
                }}
                onChange={(event, editor) => {
                    // console.log(editor, "editor");
                    return input.onChange(editor.getData())
                }
                }
        />
        {touched &&
            (error && <span className="errorMessage">{error}</span>)
        }
    </>
) 
const Help = (props) => {

    const history = useHistory();

    const pathname = history.location.pathname.split('/');
 
 
    const filtered = pathname.filter(function (el) {
         if(el !== ""){
             return el;
         }
    });
     
    let path = '/';
 
    if(filtered.length>=3){
         path += filtered[0]+"/"+filtered[1]+"/"+filtered[2];
    }
    else {
        path += filtered[0]+"/"+filtered[1] ?? '';
    }
    const [sending, setSending] = useState(false);
    const { handleSubmit, submitting} = props;
    const [data, setData] = useState({});
    const MODULE_NAME = 'cms';

     //values
     const [ values, setValues ] = useState({
        slug:"",
        description:"",
    });
   
    //get content
    const getContent = async () => {
        try{
            // let slug  = 'app_info';
            let query = {
                slug: "help",
                MODULE_NAME
            };
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getContent(query)).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                props.dispatch(actions.persist_store({ editContent:res.body}));
                setData(res.body);
            });
        }catch(err) {
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    useEffect(() => {
        getContent();  
    }, []);

     //submit content
    const handleSubmit_ = async (values) => {
        try{
            let data = {
                slug : 'help',
                description : values.description ? values.description : props?.initialValues?.description
            }
            setSending(true);
            // return
            await props.dispatch(AuthService.updateContent(data)).then((res) => {
                setSending(false);
                getContent();
                swal("Success!", res.message, "success");
            });
        }catch(err) {
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    } 

    return(
        <>
            <Helmet title="CMS" />
                <section className="section">
                <div className="section-header">
                    <h1>CMS</h1>
                </div>
                        {/* {data && */}
                    <div className="section-body">
                        <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                        
                            <div className="card-body">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <Link className={classNames("nav-link", {'active' : (path===Path.cms_about)})}  to={Path.cms_about}>About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={classNames("nav-link", {'active' : (path===Path.cms_privacy_policy)})}  to={Path.cms_privacy_policy}>Privacy Policy</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={classNames("nav-link", {'active' : (path===Path.cms_terms)})} to={Path.cms_terms}>Terms & Condition</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={classNames("nav-link", {'active' : (path===Path.help)})} to={Path.help}>Help</Link>
                                </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div className="col-md-12">
                                <div className="card">
                                    <form onSubmit={handleSubmit(handleSubmit_)}>
                                        <div className="card-header">
                                            <h4>{data ? data.title : ''}</h4>
                                        </div>
                                        <div className="card-body cms-body">
                                            <div className="row">
                                                <div className="form-group col-md-12 col-12">
                                                    <label></label>
                                                    <Field 
                                                        name="description" 
                                                        component={renderckEditor}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer text-right">
                                            <LoadingButton
                                                type="submit"
                                                className="btn btn-primary"
                                                loading={sending}
                                                disabled={submitting}
                                            >
                                            {!sending ? 'Save Changes' : '' }
                                            </LoadingButton>
                                        </div>
                                    </form>
                                </div>
                            </div>
                                </div>
                                
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                        {/* } */}

                </section>
                
        </>
    )
}

const initialValues = (values) => {	
	if(values) {
        let slug = values.slug=="help" ? true : false;
		return {
			...values,
            description: slug ? values.description : "",
		}
	}
}

const mapStateToProps = (state) => {
    return{
        initialValues: initialValues(state.persistStore.editContent),
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader:state.persistStore.loader
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const CmsHelpForm = reduxForm({
    form: 'help',
    enableReinitialize: true
	// validate
})(Help);

export default connect(mapStateToProps, mapDispatchToProps)(CmsHelpForm);
