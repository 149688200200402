//auth
export const login = '/admin/login';
export const reset_password = '/reset_password/:id';
//dashboard
export const dashboard = '/admin/dashboard';
//cms
export const cms = '/cms/:slug';
export const cms_about= '/admin/cms/about';
export const cms_privacy_policy = '/admin/cms/privacy_policy';
export const cms_terms = '/admin/cms/terms';
export const help = '/admin/cms/help';
//profile
export const profile = '/admin/profile';
export const change_password = '/admin/change_password';
//users
export const users = '/admin/user/list';
export const userview = '/admin/user/view';
export const user_view = '/admin/user/view/:id';
//restaurant_type
export const restaurant_type = '/admin/restaurant_type/list';
export const restaurant_type_form = '/admin/restaurant_type/form';
export const restaurant_type_form_edit = '/admin/restaurant_type/form/:id';
export const restaurant_typeview = '/admin/restaurant_type/view';
export const restaurant_type_view = '/admin/restaurant_type/view/:id';

//offers
export const offer = '/admin/offer/list';
export const offer_list = '/admin/offer/list/:resto_id';
export const offer_form = '/admin/offer/form';
export const offerform = '/admin/offer/form/:resto_id';
export const offer_form_edit = '/admin/offer/form/:resto_id/:id';
export const offer_formedit = '/admin/offer/form';
export const offerview = '/admin/offer/view';
export const offer_view = '/admin/offer/view/:id';

//offer types
export const offer_type = '/admin/offer_type/list';
export const offer_type_form = '/admin/offer_type/form';
export const offer_type_form_edit = '/admin/offer_type/form/:id';
export const offer_typeview = '/admin/offer_type/view';
export const offer_type_view = '/admin/offer_type/view/:id';

//offer category
export const offer_category = '/admin/offer_category/list';
export const offer_category_form = '/admin/offer_category/form';
export const offer_category_form_edit = '/admin/offer_category/form/:id';
export const offer_categoryview = '/admin/offer_category/view';
export const offer_category_view = '/admin/offer_category/view/:id';

//location
export const location = '/admin/location/list';
export const location_form = '/admin/location/form';
export const location_form_edit = '/admin/location/form/:id';

//restaurant
export const restaurant = '/admin/restaurant/list';
export const restaurant_form = '/admin/restaurant/form';
export const restaurant_form_edit = '/admin/restaurant/form/:id';
// export const restaurant_formedit = '/admin/restaurant/view';
export const restaurantview = '/admin/restaurant/view';
export const restaurant_view = '/admin/restaurant/view/:id';

//ads
export const ads = '/admin/ads/list';
export const ads_form = '/admin/ads/form';

//banner
export const banners = '/admin/banners/list';
export const banner_form = '/admin/banners/form';

//coupon
export const redeem_coupon = '/admin/coupon/list';
export const redeemcoupon = '/admin/coupon/list/:user_id';
export const redeem_coupon_view = '/admin/coupon/view';
export const redeem_couponview = '/admin/coupon/view/:user_id/:id';

//rating
export const rating = '/admin/rating/list';
export const rating_view = '/admin/rating/view';
export const ratingview = '/admin/rating/view/:id';

//redeemoffer
export const redeemoffer = '/admin/redeemed_coupon/list';
export const redeemoffer_view = '/admin/redeemed_coupon/view';
export const redeemofferview = '/admin/redeemed_coupon/view/:id';

//tags
export const tag = '/admin/tag/list';
export const tag_form = '/admin/tag/form';
export const tag_form_edit = '/admin/tag/form/:id';

//cuisine
export const cuisine = '/admin/cuisine/list';
export const cuisine_form = '/admin/cuisine/form';
export const cuisine_form_edit = '/admin/cuisine/form/:id';


export const notification = '/admin/notification/list';
// export const location_form = '/admin/location/form';
// export const location_form_edit = '/admin/location/form/:id';