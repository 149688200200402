import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import { reduxForm } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ImageUploader from 'react-images-upload';
import ImageUploading from 'react-images-uploading';
import InputArrayField from './InputArrayField';
import Geosuggest from 'react-geosuggest';
import './geosuggest.css'; // Import the CSS file
import Select from 'react-select';
import TextInput from "components/shared/TextInput";

const Add = (props) => {

    const MODULE_NAME = 'restaurant';
    //history
    const history = useHistory();

    const [sending, setSending] = useState(false);
  
    const { handleSubmit, submitting} = props;
    const [newValues, setNewValues] = useState({
    });


    const [address, setAddress] = useState('');

    //images
    const [ images, setImages] = useState([]);    
    const [ imageError, setImageError] = useState(false);    
    const maxNumber = 5;

    //resto_menu 
    const [pdf, setPdf] = useState(null);
    const [dbPdf, setdbPdf] = useState({});
    const [pdfName, setPdfName] = useState("");
    const [pdfError, setPdfError] = useState(null);

    const [ hoursError, setHoursError] = useState(false);  
    //type
    const [ typeId, setTypeId] = useState(null);  
    //typeCatgeoryId
    const [ typeCatgeoryId, setTypeCatgeoryId] = useState(null); 
    const [ offerCategoryType, setOfferCatgeoryType] = useState([]); 
    //location
    const [ locationId, setLocationId] = useState(null); 
    //offer type
    const [ offerType, setOfferType] = useState([]);
    //location   
    const [ locations, setLocations] = useState([]);  
    //selected tags option 
    const [selectOption, setSelectOption] = useState([]);
    const [tagsOptions, setTagsOptions] = useState([]);

    const [businessOptions, setBusinessOptions] = useState([]);
    const [businessOption, setBusinessOption] = useState([]);

    //selected cuisine option 
    const [selectCuisineOption, setSelectCuisineOption] = useState([]);
    const [cuisineOptions, setCuisineOptions] = useState([]);

    //show link pdf
    const [showDesc, setShowDesc] = useState(false);

    const [locationValues, setLocationValues] = useState({
        'location':'',
        'latitude':'',
        'longitude':''
    });

    const initialValues = {
        name: newValues ? newValues.name : '',
        // type: newValues ? newValues.type : '',
        email: newValues ? newValues.email : '',
        phone: newValues ? newValues.phone : '',
        pdf_link: newValues ? newValues.pdf_link : '',
        website_url: newValues ? newValues.website_url : '',
        facebook_url: newValues ? newValues.facebook_url : '',
        instagram_url: newValues ? newValues.instagram_url : '',
        location: 'Atlanta',
        latitude: locationValues ? locationValues.latitude : '',
        longitude: locationValues ? locationValues.longitude : '',
        reservation_url: newValues ? newValues.reservation_url : '',
        social_url: newValues ? newValues.social_url : '',
        tag_ids: '',
        cuisine_ids:'',
        // price: newValues ? newValues.price : '',
        description: newValues ? newValues.description : '',
        currency_type: newValues ? newValues.currency_type : '',
        offer_type_id: newValues ? newValues.offer_type_id : '',
        offer_type_category_id: newValues ? newValues.offer_type_category_id : '',
        location_id: "4",
        open_hours: [
            {
              title: '',
              start_time:'',
              end_time: '',
              start_time_format: '',
              end_time_format: '',
              is_closed: false,
            },
        ],
    };
    //show link pdf
    const showDescriptionFunction = (val) => {
        setShowDesc(val)
    }

    //handle change
    const handleChanges = (e) => {
        const { name, value } = e.target;
        setNewValues({
            ...newValues,
            [name]: value
        });
    };
    //select tags
    const handleChangeSelect = (value) => {
        setSelectOption(value);
    };

    const handleChangeBusiness = (value) => {
        setBusinessOption(value);
    };

    //select cuisine
    const handleChangeSelectCuisine = (value) => {
        setSelectCuisineOption(value);
    };

    //get offer type
    async function getOfferTypes() {
        try{
            let query ={
                MODULE_NAME,
                URL_NAME : 'offer_type_list_with_category'
            }
           setSending(false);
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
               setSending(false);
               props.dispatch(actions.persist_store({ loader:false}));
               setOfferType(res.body);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
        
    }

    //get offer category
    async function getOfferCategory(id) {
        try{
            let query ={
                MODULE_NAME : 'restaurant_offer',
                URL_NAME : 'category_by_offer_type',
                id: id
            }
           setSending(false);
        //    props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
               setSending(false);
            //    props.dispatch(actions.persist_store({ loader:false}));
               setOfferCatgeoryType(res.body);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }

    //get location 
    async function getLocation() {
        try{
            let query ={
                MODULE_NAME,
                URL_NAME : 'location_list'
            }
           setSending(false);
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
               setSending(false);
               props.dispatch(actions.persist_store({ loader:false}));
               setLocations(res.body);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }

    //get option tags 
    async function getTags() {
        try{
            let query ={
                MODULE_NAME,
                URL_NAME : 'tag_list'
            }
           setSending(false);
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
               setSending(false);
               props.dispatch(actions.persist_store({ loader:false}));
            //    setTagsOtions(res.body);
               let tag_options = res.body
               let arr = []
                for(let option of tag_options){
                    // if(model.dashboard==true){
                        let data = {
                            value : option.id,
                            label : option.name
                        }
                        arr.push(data)
                    // }
                }
                setTagsOptions(arr);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }

     //get option getBusiness 
     async function getBusiness() {
        try{
            let query ={
                MODULE_NAME,
                URL_NAME : 'all_res_list'
            }
           setSending(false);
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
               setSending(false);
               props.dispatch(actions.persist_store({ loader:false}));
            //    setTagsOtions(res.body);
               let tag_options = res.body
               let arr = []
                for(let option of tag_options){
                    // if(model.dashboard==true){
                        let data = {
                            value : option.id,
                            label : option.name
                        }
                        arr.push(data)
                    // }
                }
                setBusinessOptions(arr);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }

    //get option cuisine 
    async function getCuisine() {
        try{
            let query ={
                MODULE_NAME,
                URL_NAME : 'cuisine_list'
            }
           setSending(false);
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
               setSending(false);
               props.dispatch(actions.persist_store({ loader:false}));
            //    setTagsOtions(res.body);
               let cuisine_options = res.body
               let arr = []
                for(let option of cuisine_options){
                    // if(model.dashboard==true){
                        let data = {
                            value : option.id,
                            label : option.name
                        }
                        arr.push(data)
                    // }
                }
                setCuisineOptions(arr);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }

    useEffect(() => {
        getOfferTypes();
        getLocation();
        getTags();
        getCuisine();
        getBusiness();
    }, []);

    //profile image 
    const uploadPdf = (picture, base64) => {
        // setPdfError(false);
        setPdfName(picture[0].name);
        setPdf(picture[0]);
    }

     //render menu pdf
	const renderMenuPDF = (name, buttonText) => {
        let defaultPDF = '';
        // if(dbPdf && name==='resto_menu'){
        //     defaultPDF = dbPdf && dbPdf.original ? env.SERVER_URL+dbPdf.original : '';
        // }
        return(
             <div className="profile_images_boxs pdf-design">
                 <ImageUploader
                     name={name}
                     withIcon={false}
                     withPreview={false}
                     singleImage={true}
                     buttonText={buttonText}
                     onChange={(picture) => uploadPdf(picture, name)}
                     imgExtension={[".pdf"]}
                     fileSizeError="file size is too big"
                     accept="application/pdf"
                     label={<div className="label-image-profile">{pdfName ? pdfName : '' }</div>}
                    //  defaultImages={ defaultImage ? [defaultImage] : [] }
                 />
                    {/* {(dbPdf && dbPdf.original) &&
                    <>
                        <p>
                            <a href={dbPdf && dbPdf.original ? env.SERVER_URL+dbPdf.original : '#'} target="_blank">
                                click here to view pdf
                            </a>
                        </p>
                    </>
                    } */}
             </div>
         )
    };
  
    //images
    let emptyArray = [...images]
    const onChange = (imageList) => {
        if(images.length>maxNumber){
            setImageError(true);
        }else{
            setImageError(false);
            imageList.forEach((image, index) => {
                emptyArray.push(image)
                setImages([...images, image]);
                
            });
            emptyArray = getUniqueListBy(emptyArray, 'data_url')
            let myNewImageArray = emptyArray.filter(function( obj ) {
                return obj.data_url
            });
            setImages([...myNewImageArray])
        }  
    };

    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    const handleRemoveImgClick = async (img) => {
        let arr;
        if(img.data_url){
            arr = images.filter((item) => item.data_url !== img.data_url);
        }else{
            let query = {
                MODULE_NAME,
                // id:editkey,
                image_id:img.id,
            };

            await props.dispatch(AuthService.deleteImgData(query)).then((res) => {
               arr = images.filter((item) => item.original !== img.original);
            });
        }
        setImages(arr);
    };

    //images
    const restaurant_images = () => {
        return (
           <>
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                >
                {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors,
                }) => (
                    // write your building UI
                    <>
                    <div className="form-row " style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}>
                        <img src="/assets/img/news/img01.jpg" onClick={() => onImageRemove(0)} style={{width:'100px', height:'100px', margin:'12px'}} />
                    </div>
                    {errors && <div style={{color:'red'}}>
                    {errors.maxNumber && <span>You can select upto 5 images same time</span>}
                    </div>}
                    <div className="row">
                        {images.length>0 && images.map((image, index) => {
                            let imgData = image['data_url'];
                            return(
                            <div className="row">    
                            <div className="col-md-4">    
                                <div className="image-item-event" key={index}>
                                    <div className="close-img-item" onClick={() => handleRemoveImgClick(image)}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                    <img src={imgData} alt="" style={{width:'100px', height:'100px', marginLeft:'20px'}}/>
                                </div>
                            </div>
                            </div>
                            )
                        })}
                    </div>
                    </>
                )}
            </ImageUploading> 
           </>
        )

    }

    //submit data=================================
    const submitData_ = async (values, { setSubmitting, resetForm }) => {
        try{
                let finalArrTags = []
                for(let option of selectOption){
                    let modules_data = {
                        value : option.value,
                    }
                    finalArrTags.push(modules_data.value)
                }
                let finalArrCuisine = []
                for(let options of selectCuisineOption){
                    let modules_data = {
                        value : options.value,
                    }
                    finalArrCuisine.push(modules_data.value)
                }
                let query = {};
                const formData = new FormData(); 
                const formData2 = new FormData(); 
                if(values.open_hours && values.open_hours.length==0){
                    setHoursError(true)
                }
                if(images.length==0){
                    setImageError(true);
                }
                else{
                    setHoursError(false);
                    setImageError(false);
                    let hours_values =  values.open_hours;
                    let newHours = []
                    for(let i in hours_values){
                        let obj = {
                            start_time : hours_values[i].start_time+' '+hours_values[i].start_time_format,
                            end_time : hours_values[i].end_time+' '+hours_values[i].end_time_format,
                            title : hours_values[i].title,
                            is_closed : hours_values[i].is_closed
                        }
                        newHours.push(obj)
                    }
                    // setPdfError(false);
                    let myNewImageArray = images.filter(function( obj ) {
                        return obj.data_url
                    });
                    if(myNewImageArray.length > 0 && myNewImageArray[0]['data_url']){
                        for ( let key in myNewImageArray ) {
                            if(myNewImageArray[key]['file']){
                                formData.append('image', myNewImageArray[key]['file']);
                            }
                        }
                        query.image = myNewImageArray.length > 0 && myNewImageArray[0]['data_url'] ? [...myNewImageArray] : ''
                        formData.append('type', 'image');
                        formData.append('folder', 'restaurant');
                        setSending(true);
                        await props.dispatch(AuthService.fileUpload(formData)).then((res) => {
                            setSending(false);
                            let newImg = res.body;
                            query.image = JSON.stringify(newImg)
                        })
                    }
                    if(pdf!=null && showDesc==false){
                        formData2.append('image', pdf);
                        formData2.append('type', 'pdf');
                        formData2.append('folder', 'restaurant');
                        await props.dispatch(AuthService.fileUpload(formData2)).then((res) => {
                            let newPdf = res.body;
                            query.resto_menu = JSON.stringify(newPdf)
                        });
                    }
                    if(showDesc){
                        query.resto_menu = values.pdf_link
                    }
                    setSending(true);
                    query = {
                        ...query,
                        MODULE_NAME,
                        name:values.name,
                        email:values.email,
                        phone:values.phone,
                        title:values.type,
                        description:values.description,
                        website_url:values.website_url,
                        currency_type:values.currency_type,
                        reservation_url:values.reservation_url,
                        facebook_url:values.facebook_url,
                        instagram_url:values.instagram_url,
                        social_url:values.social_url,
                        latitude:values.latitude,
                        longitude:values.longitude,
                        menu_type:showDesc ? 2 : 1,
                        location:values.location,
                        offer_type_id:typeId ? typeId.offer_type_id : '',
                        offer_type_category_id:typeCatgeoryId ? typeCatgeoryId.offer_type_category_id : '',
                        location_id: "4",
                        price:'0.0',
                        google_avg_count:'0.0',
                        open_hours:JSON.stringify(newHours),
                        tag_ids:JSON.stringify(finalArrTags),
                        cuisine_ids:JSON.stringify(finalArrCuisine),
                    }
                    // return
                    await props.dispatch(AuthService.createData(query)).then((res) => {
                        setSending(false);
                        swal("Success", res.message, "success");
                        history.push(Path.restaurant);
                        
                    });
                }
        } catch(err) {
             console.log(err);
             setSending(false);
              if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

   //handle change type
    const handleChangeType = (e) => {
        const { name, value } = e.target;
        setTypeId({
            ...typeId,
            [name]: value
        });
        getOfferCategory(value)
    };
   //handle change location
    const handleChangeLocation = (e) => {
        const { name, value } = e.target;
        setLocationId({
            ...locationId,
            [name]: value
        });
    };

    const handleInputChange = (value) => {
        if (value.length === 1) {
            setAddress('');
          } else {
            setAddress(value);
        }
      };
    
    const handleChangeTypeCategory = (e) => {
        const { name, value } = e.target;
        setTypeCatgeoryId({
            ...typeCatgeoryId,
            [name]: value
        });
    };
   
    const onPlaceSelected = (place) => {
        const address = place.description;
        const latValue = place.location.lat;
        const  lngValue = place.location.lng;
        setLocationValues({
            'location': address,
            'latitude': latValue,
            'longitude': lngValue
        });
    };
    const [isCheckboxChecked, setCheckboxChecked] = useState(false);
    const handleChangeCheckbox = (e) => {
        setCheckboxChecked(e.target.checked);
    };

    return(
        <>
            <Helmet title="Business" />
            <section className="section">
                <div className="section-header">
                    <h1>Add Business</h1>
                </div>
                {!props.loader &&
                <div className="section-body">
                        <div className="row mt-sm-4">
                            <div className="col-12 col-md-12 col-lg-7 offset-md-2">
                                <div className="card">
                                <Formik
                                    initialValues={initialValues}
                                    enableReinitialize
                                    validationSchema={validation}
                                    onSubmit={submitData_}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="card-header">
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Type*</label>
                                                        {offerType.length > 0 && offerType.map((item, key) => (
                                                            <div key={key} className="form-check">
                                                                <input
                                                                    className={`form-check-input ${errors.offer_type_id && 'is-invalid'}`}
                                                                    type="checkbox" // Change radio to checkbox here
                                                                    id={`offerType_${key}`}
                                                                    name="offer_type_id"
                                                                    value={item.id}
                                                                    onChange={handleChangeType}
                                                                    required
                                                                />
                                                                <label className="form-check-label" htmlFor={`offerType_${key}`}>
                                                                    {item.name}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Has Directory</label>
                                                        <div className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                className={`form-check-input ${errors.offer_type_id && 'is-invalid'}`}
                                                                name="offer_type_id"
                                                                checked={isCheckboxChecked}
                                                                onChange={handleChangeCheckbox}
                                                                // value={item.id}
                                                                // onChange={handleChangeType}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {isCheckboxChecked && (
                                                <div className="row">                               
                                                    <div className="form-group col-md-12 col-6">
                                                        <label>Business</label>
                                                        <Select
                                                            value={businessOption}
                                                            onChange={handleChangeBusiness}
                                                            options={businessOptions}
                                                            isMulti={true}
                                                        />
                                                    </div>
                                                </div>
                                                )}
                                                <hr/>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Business Name*</label>
                                                        <input 
                                                            name="name" 
                                                            type="text"
                                                            className="form-control"
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                            maxLength={100}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="name"
                                                        />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Category*</label>
                                                        <select 
                                                            className={`form-control ${errors.offer_type_category_id && 'is-invalid'}`}
                                                            name="offer_type_category_id"
                                                            onChange={handleChangeTypeCategory}
                                                            // onBlur={handleBlur}
                                                            required
                                                        >
                                                            <option value="" selected disabled>Select</option>
                                                            {offerCategoryType.length>0 && offerCategoryType.map((item, key) => {
                                                                return(
                                                                    <option key={key} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Neighbourhood</label>
                                                        <TextInput 
                                                            className={`form-control ${errors.offer_type_id && 'is-invalid'}`}
                                                            name="location_id"
                                                            onChange={handleChangeLocation}
                                                            // required
                                                        >                                                    
                                                        </TextInput>
                                                        {(errors.location_id && touched.location_id) &&
                                                            <span className="invalid-feedback">{errors.location_id}</span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row">                               
                                                    <div className="form-group col-md-12 col-6">
                                                        <label>Atmosphere</label>
                                                        <Select
                                                            value={selectOption}
                                                            onChange={handleChangeSelect}
                                                            options={tagsOptions}
                                                            isMulti={true}
                                                        />
                                                        {(errors.tag_ids && touched.tag_ids) &&
                                                            <span className="invalid-feedback">{errors.tag_ids}</span>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="row">                               
                                                    <div className="form-group col-md-12 col-6">
                                                        <label>Atmosphere</label>
                                                        <Select
                                                            value={selectCuisineOption}
                                                            onChange={handleChangeSelectCuisine}
                                                            options={cuisineOptions}
                                                            isMulti={true}
                                                        />
                                                        {(errors.cuisine_ids && touched.cuisine_ids) &&
                                                            <span className="invalid-feedback">{errors.cuisine_ids}</span>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Address*</label>
                                                        <div style={{ cursor: 'pointer' }}>
                                                        <Geosuggest
                                                            placeholder="Enter an address"
                                                            onSuggestSelect={onPlaceSelected}
                                                            onChange={handleInputChange}
                                                            initialValue={address}
                                                            style={{ width: '100%', height: '40px', fontSize: '16px', borderRadius: '4px',    }}
                                                            inputClassName="form-control"
                                                        />
                                                        </div>
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="location"
                                                        />
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Email</label>
                                                        <input 
                                                            name="email" 
                                                            type="email"
                                                            className={`form-control ${errors.email && 'is-invalid'}`}
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="email"
                                                        />
                                                        </p>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Phone</label>
                                                        <input 
                                                            name="phone" 
                                                            type="text"
                                                            className={`form-control ${errors.phone && 'is-invalid'}`}
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.phone}
                                                            maxLength={15}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="phone"
                                                        />
                                                        </p>
                                                    </div>
                                                </div>
                                                
                                                {/* <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Type/Cuisine*</label>
                                                        <input 
                                                            name="type" 
                                                            type="text"
                                                            className={`form-control ${errors.type && 'is-invalid'}`}
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.type}
                                                            maxLength={100}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="type"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Website Url</label>
                                                        <input 
                                                            name="website_url" 
                                                            type="text"
                                                            className={`form-control ${errors.website_url && 'is-invalid'}`}
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.website_url}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="website_url"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Reservation Url</label>
                                                        <input 
                                                            name="reservation_url" 
                                                            type="text"
                                                            className={`form-control ${errors.reservation_url && 'is-invalid'}`}
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.reservation_url}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="reservation_url"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Instagram Url</label>
                                                        <input 
                                                            name="instagram_url"
                                                            type="text"
                                                            className={`form-control ${errors.instagram_url && 'is-invalid'}`}
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.instagram_url}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="instagram_url"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Facebook Url</label>
                                                        <input 
                                                            name="facebook_url" 
                                                            type="text"
                                                            className={`form-control ${errors.facebook_url && 'is-invalid'}`}
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.facebook_url}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="facebook_url"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>Social Url</label>
                                                        <input 
                                                            name="social_url" 
                                                            type="text"
                                                            className={`form-control ${errors.social_url && 'is-invalid'}`}
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.social_url}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="social_url"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>$Price Sign*</label>
                                                        <select 
                                                            className={`form-control ${errors.currency_type && 'is-invalid'}`}
                                                            name="currency_type"
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            required
                                                        >
                                                            <option value="" selected disabled>Select</option>
                                                            <option value="$">$</option>
                                                            <option value="$$">$$</option>
                                                            <option value="$$$">$$$</option>
                                                            <option value="$$$$">$$$$</option>
                                                           
                                                        
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>*Description</label>
                                                        <textarea
                                                            name="description" 
                                                            type="textarea"
                                                            className="form-control"
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            // value={values.description}
                                                            style={{ resize: 'vertical', minHeight: '100px' }}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="description"
                                                        />
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label><b>Add Menu</b></label>
                                                    </div>
                                                    <div className="col-md-4 form-check">
                                                        <input 
                                                            name="menu_type" 
                                                            type="radio"
                                                            className="form-check-input"
                                                            onChange={() => showDescriptionFunction(true)}
                                                        />
                                                        <label className="form-check-label" for="exampleRadios2">
                                                            Link
                                                        </label>
                                                    </div>
                                                    <div className="col-md-4 form-check">
                                                        <input 
                                                            name="menu_type" 
                                                            type="radio"
                                                            className="form-check-input"
                                                            onChange={() => showDescriptionFunction(false)}
                                                        />
                                                        <label className="form-check-label" for="exampleRadios2">
                                                            PDF
                                                        </label>
                                                    </div>
                                                </div>
                                                <br/>
                                                {!showDesc &&
                                                <>
                                                <div><label for="exampleInputEmail1">Upload menu (PDF format)</label></div>
                                                
                                                {renderMenuPDF('resto_menu', <img src='/assets/images/upld.png' className="img-user" />)}
                                                </>
                                                }
{/*                                                 
                                                {pdfError && 
                                                    <span className="errorMessage"><b>Required*</b></span>
                                                } */}
                                                <br />
                                                {showDesc &&
                                                <div className="row">
                                                    <div className="form-group col-md-12 col-12">
                                                        <label>PDF Link</label>
                                                        <input 
                                                            name="pdf_link" 
                                                            type="text"
                                                            className={`form-control ${errors.pdf_link && 'is-invalid'}`}
                                                            onChange={(e) => handleChanges(e)}
                                                            onBlur={handleBlur}
                                                            value={values.pdf_link}
                                                        />
                                                        <p className="errorMessage">
                                                        <ErrorMessage
                                                            className="form-text text-danger"
                                                            name="pdf_link"
                                                        />
                                                        </p>
                                                     
                                                    </div>
                                                </div>
                                                }
                                                <InputArrayField values={values} errors={errors} hoursError={hoursError} />

                                                <label for="exampleInputEmail1">Image (You can select upto 5 Images same time)</label>
                                                <br/>
                                                {restaurant_images()}
                                                {imageError && 
                                                    <span className="errorMessage"><b>Required*</b></span>
                                                }
                                            </div>
                                            <div className="card-footer float-right col-md-4">
                                                <LoadingButton
                                                    type="submit"
                                                    className="btn btn-primary btn-lg btn-block"
                                                    loading={sending}
                                                    disabled={submitting}
                                                >
                                                {!sending ? 'Save Changes' : ''}
                                                </LoadingButton>
                                            </div>
                                    </form>
                                    )}
                                </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </section>
        </>
    )
}

const validation = Yup.object().shape({
    name: Yup.string().trim().required("Required*"), 
    website_url: Yup.string().url('Invalid URL format'), 
    reservation_url: Yup.string().url('Invalid URL format'), 
    pdf_link: Yup.string().url('Invalid URL format'), 
    instagram_url: Yup.string().url('Invalid URL format'),  
    facebook_url: Yup.string().url('Invalid URL format'),  
    social_url: Yup.string().url('Invalid URL format'),  
    // currency_type: Yup.string().trim().required("Required*"), 
    // description: Yup.string().trim().required("Required*"), 
    open_hours: Yup.array()
    .of(
      Yup.object().shape({
      //  title: Yup.string().trim().required('Required*'),
       // start_time: Yup.string().trim().required('Required*'),
        //end_time: Yup.string().trim().required('Required*'),
      })
    )
});



const mapStateToProps = (state, props) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const AddForm = reduxForm({
	form: 'add',
    enableReinitialize:true
})(Add);

export default connect(mapStateToProps, mapDispatchToProps)(AddForm);