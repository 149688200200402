import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import { Field, reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';

const renderField = ({
    input,
    placeholder,
    type,
    autoComplete,
    meta: { touched, error, warning }
}) => (
    <>
        <input {...input} type={type} placeholder={placeholder} autoComplete={autoComplete} className={`form-control ${touched && error ? `is-invalid` : ''}`}/>
        
        {touched &&
            (error && <span className="invalid-feedback">{error}</span>)
        }
    </>
)
const AddEdit = (props) => {
    //history
    const history = useHistory();

    let editkey = props && props.match.params.id;
    const [sending, setSending] = useState(false);
    const { handleSubmit, submitting} = props;
    const [data, setData] = useState({});
    const [offerType, setOfferType] = useState([]);

    //type
    const [ typeId, setTypeId] = useState(null);  
    
    const [values, setValues] = useState({
        address:"",
        latitude:"",
        longitude:"",
    });
    const MODULE_NAME = 'location';

    //get data
    async function getData() {
        try{
            let query={
                MODULE_NAME,
                id:editkey
            }
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                props.dispatch(actions.persist_store({ editType:res.body}));
                props.dispatch(actions.persist_store({ loader:false}));
                setSending(false);
                setData(res.body);
                // setTypeId(res.body.offer_type_id)
            });
        }catch(err) {
            setSending(false);
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

       useEffect(() => {
            if(editkey){
                getData();
            }
       }, []);

    const handleSubmit_ = async (values) => {
        try{
            let query={
                MODULE_NAME,
                address:values.address,
                latitude:'30.7046',
                longitude:'76.7179'
            }
            setSending(true);
            if(editkey){
                query.location_id = editkey;
                await props.dispatch(AuthService.updateData(query)).then((res) => {
                    setSending(false);
                    swal("Success!", res.message, "success");
                    setValues({
                        name:"",
                    });
                    props.dispatch(actions.persist_store({ editType:""}));
                    props.dispatch(reset('addEdit'));
                    history.push(Path.location);
                });
            }
            else{
                await props.dispatch(AuthService.createData(query)).then((res) => {
                    setSending(false);
                    swal("Success", res.message, "success");
                    history.push(Path.location);
                });
            }
        }catch(err) {
             console.log(err);
             setSending(false);
              if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

     //offer types
     async function getOfferType() {
        try{
            let query={
                MODULE_NAME :'restaurant_offer',
                URL_NAME:'all_list',
            }
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getOtherList(query)).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                setSending(false);
                setOfferType(res.body);
            });
        }catch(err) {
            setSending(false);
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    useEffect(() => {
        getOfferType();
    }, []);

    //handle change type
    const handleChangeType = (e) => {
        const { name, value } = e.target;
        setTypeId({
            ...typeId,
            [name]: value
        });
    };

    return(
        <>
            <Helmet title="Locations" />
            <section className="section">
                <div className="section-header">
                    <h1>{editkey ? 'Edit' : 'Add'} Location</h1>
                </div>
                {!props.loader &&
                <div className="section-body">
                        <div className="row mt-sm-4">
                            <div className="col-12 col-md-12 col-lg-7 offset-md-2">
                                <div className="card">
                                <form onSubmit={handleSubmit(handleSubmit_)}>
                                    <div className="card-header">
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-md-12 col-12">
                                                <label>Location*</label>
                                                <Field 
                                                    name="address" 
                                                    component={renderField}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="card-footer float-right col-md-4">
                                        <LoadingButton
                                            type="submit"
                                            className="btn btn-primary btn-lg btn-block"
                                            loading={sending}
                                            disabled={submitting}
                                        >
                                        {!sending ? `${editkey ? 'Update' : 'Save'}` +' Changes' : ''}
                                        </LoadingButton>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </section>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    //address
    if (!values.address) {
      errors.address = 'Required*'
    }
    return errors
}

const initialValues = (values) => {	
	if(values) {
		return {
			...values,
			address: values.address  ? values.address : "",
        }
	}
}

const mapStateToProps = (state) => {
    return{
        initialValues:initialValues(state.persistStore.editType),
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const AddEditForm = reduxForm({
	form: 'addEdit',
	validate,
    enableReinitialize:true
})(AddEdit);

export default connect(mapStateToProps, mapDispatchToProps)(AddEditForm);