import React, {useState} from "react";
import { Field, FieldArray, ErrorMessage } from 'formik';

//imported
import LoadingButton from 'components/shared/LoadingButton';

const InputArrayField = ({values, errors, hoursError}) => {
        
       /*  //start time----------------------
        var x = 60; // minutes interval
        var start_times = []; // time array
        var tt = 0; // start time
        
        // loop to increment the time and push results in array
        for (var i = 1; i <= 12; i++) {
            var hh = i; // hours from 1 to 12
            var mm = 0; // minutes at the beginning of the hour
            start_times.push(("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2));
        }

        
        //end time----------------------
        var x = 60; // minutes interval
        var end_times = []; // time array
        var tt = 0; // start time
        
        // loop to increment the time and push results in array
        for (var i = 1; i <= 12; i++) {
            var hh = i; // hours from 1 to 12
            var mm = 0; // minutes at the beginning of the hour
            end_times.push(("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2));
        } */

          
        //start time----------------------
        var x = 30; // minutes interval
        var start_times = []; // time array
        var hh = 1; // start hour
        var mm = 0; // start minute

        // loop to increment the time and push results in array for 12 hours
        for (var i = 0; i < (12 * 60 / x); i++) {
            start_times.push(("" + hh).slice(-2) + ':' + ("0" + mm).slice(-2));
            
            // Increment time by 30 minutes
            mm += x;

            // Check if minutes exceed 59, then increment hours and reset minutes
            if (mm >= 60) {
                mm = 0;
                hh += 1;
            }

            // If hours exceed 12, reset to 1
            if (hh > 12) {
                hh = 1;
            }
        }
     
        //end time----------------------
        var x = 30; // minutes interval
        var end_times = []; // time array
        var hh = 1; // start hour
        var mm = 0; // start minute

        // loop to increment the time and push results in array for 12 hours
        for (var i = 0; i < (12 * 60 / x); i++) {
            end_times.push(("" + hh).slice(-2) + ':' + ("0" + mm).slice(-2));
            
            // Increment time by 30 minutes
            mm += x;

            // Check if minutes exceed 59, then increment hours and reset minutes
            if (mm >= 60) {
                mm = 0;
                hh += 1;
            }

            // If hours exceed 12, reset to 1
            if (hh > 12) {
                hh = 1;
            }
        }
    

    return(
        <>
            <label>Open Hours</label>
            <FieldArray name="open_hours">
                {({ form, insert, remove, push }) => (
                    <div className="row time-seat">
                    {values.open_hours.length>0 && values.open_hours.map((hours, index) => {
                        return(
                        <div className="row" key={index}>
                            <div className="form-group col-md-4">
                                <label>Title*</label>
                                <Field 
                                    className="form-control"
                                    name={`open_hours.${index}.title`} 
                                    type="text"
                                    // onChange={(e) => handleChanges(e)}

                                />
                                <p className="errorMessage">
                                <ErrorMessage
                                    className="form-text text-danger"
                                    name={`open_hours.${index}.title`}
                                />
                                </p>
                            </div>
                            {form.values.open_hours[index].is_closed==false &&
                            <>
                                <div className="form-group col-md-3">
                                    <label>Start Time*</label>
                                    <Field as="select" class="form-control"  name={`open_hours.${index}.start_time`}>
                                        <option value="" disabled>--:--</option>
                                        {start_times.length>0 && start_times.map((start, i)=>{
                                            return(
                                                <option value={start}>{start}</option>
                                            )
                                        })}
                                    </Field>
                                    <p className="errorMessage">
                                    <ErrorMessage
                                        className="form-text text-danger invalid-feedback"
                                        name={`open_hours.${index}.start_time`}
                                    />
                                    </p>
                            </div>
                                <div className="form-group col-md-3">
                                    <label>AM/PM*</label>
                                    <Field as="select" class="form-control"  name={`open_hours.${index}.start_time_format`}>
                                        <option value="" disabled>--:--</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                        
                                    </Field>
                                    <p className="errorMessage">
                                    <ErrorMessage
                                        className="form-text text-danger invalid-feedback"
                                        name={`open_hours.${index}.start_time_format`}
                                    />
                                    </p>
                                </div>
                                
                                <div className="form-group col-md-3">
                                    <label>End Time*</label>
                                    <Field as="select" class="form-control" name={`open_hours.${index}.end_time`} >
                                        <option value="" disabled>--:--</option>
                                        {end_times.length>0 && end_times.map((end, i)=>{
                                            return(
                                                <option value={end}>{end}</option>
                                            )
                                        })}
                                    </Field>
                                    <p className="errorMessage">
                                    <ErrorMessage
                                        className="form-text text-danger invalid-feedback"
                                        name={`open_hours.${index}.end_time`}
                                    />
                                    </p>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>AM/PM*</label>
                                    <Field as="select" class="form-control"  name={`open_hours.${index}.end_time_format`}>
                                        <option value="" disabled>--:--</option>
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                        
                                    </Field>
                                    <p className="errorMessage">
                                    <ErrorMessage
                                        className="form-text text-danger invalid-feedback"
                                        name={`open_hours.${index}.end_time_format`}
                                    />
                                    </p>
                                </div>
                            </>
                            }
                           
                            <div className="form-group col-md-1">
                                <label> Is Closed</label>
                                <Field
                                    type="checkbox"
                                    name={`open_hours.${index}.is_closed`}
                                    style={{ margin: '10px' }}
                                    checked={form.values.open_hours[index].is_closed}
                                    onChange={() => {
                                        const currentValue = form.values.open_hours[index].is_closed;
                                        form.setFieldValue(`open_hours.${index}.is_closed`, !currentValue);
                                    }}
                                />
                            </div>
                            {form.values.open_hours[index].is_closed==true &&
                                <div className="form-group col-md-2">
                                </div>
                            }
                           
                            <div className="form-group col-md-1 remove-more-btn">
                                <LoadingButton
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => remove(index)}
                                >
                                    x
                                </LoadingButton>
                            </div>
                        </div>
                    )
                    })}
                    <div className="form-group col-md-1 add-more-btn">
                        <LoadingButton
                            type="button"
                            className="btn btn-primary"
                            onClick={() => push({title:'', start_time:'', end_time:'', is_closed : false, start_time_format: '', end_time_format:''})}
                        >
                    +
                        </LoadingButton>
                    </div>
                
                </div>
                )}                             
            </FieldArray>
            {hoursError &&
                <span className="errorMessage"><b>Required*</b></span>
            }
        </> 
    )
}

export default InputArrayField;