import React, {useState, useEffect} from "react";
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import env from 'env.json';
// import * as actions from 'store/actions';

const Sidebar = ({user, profile}) => {
    //history
    const history = useHistory();

    const pathname = history.location.pathname.split('/');
 
 
    const filtered = pathname.filter(function (el) {
         if(el !== ""){
             return el;
         }
    });
     
    let path = '/';
 
    if(filtered.length>=3){
         path += filtered[0]+"/"+filtered[1]+"/"+filtered[2];
    }
    else {
        path += filtered[0]+"/"+filtered[1] ?? '';
    }

    const sidebarHideShow = () => {
        if (document.body.classList.contains('sidebar-show')) {
            document.body.classList.add('sidebar-gone');
            document.body.classList.remove('sidebar-mini');
            document.body.classList.remove('sidebar-show');
        } 
    }

    useEffect(() => {
        sidebarHideShow();
    }, [])


    return(
        <div className="main-sidebar sidebar-style-2">
            <aside id="sidebar-wrapper">
                <div className="sidebar-brand">
                    <Link to={Path.dashboard} style={{cursor:'pointer'}}><img src="/assets/images/logo.png" className="main-sidebar-logo"/></Link>
                </div>
                <ul className="sidebar-menu">
                    <li className={classNames("", {'active' : (path===Path.dashboard)})} to={Path.dashboard}><Link class="nav-link" onClick={sidebarHideShow} to={Path.dashboard} title="Dashboard"><i class="fas fa-fire"></i> <span>Dashboard</span></Link></li>
                    <li className={classNames("", {'active' : (path===Path.users || path===Path.userview || path===Path.user_view || path===Path.redeem_coupon || path===Path.redeem_coupon_view || path===Path.redeemcoupon)})} to={Path.users}><Link class="nav-link"  title="User" onClick={sidebarHideShow} to={Path.users}><i class="fa fa-users"></i> <span>Users</span></Link></li>

                    <li className={classNames("", {'active' : (path===Path.offer_type || path===Path.offer_type_form || path===Path.offer_type_form_edit || path===Path.offer_typeview || path===Path.offer_type_view)})} to={Path.offer_type}><Link title="Offers Type" onClick={sidebarHideShow} class="nav-link" to={Path.offer_type}><i class="fas fa-th-large"></i> <span>Offers Type</span></Link></li>

                    <li className={classNames("", {'active' : (path===Path.tag || path===Path.tag_form_edit || path===Path.tag_form )})} to={Path.tag}><Link title="Tags" onClick={sidebarHideShow} class="nav-link" to={Path.tag}><i class="fas fa-th-large"></i> <span>Atmosphere</span></Link></li>

                    <li className={classNames("", {'active' : (path===Path.cuisine || path===Path.cuisine_form_edit || path===Path.cuisine_form )})} to={Path.cuisine}><Link title="Cuisine" onClick={sidebarHideShow} class="nav-link" to={Path.cuisine}><i class="fas fa-th-large"></i> <span>Lifestyle</span></Link></li>

                    <li className={classNames("", {'active' : (path===Path.location || path===Path.location_form_edit || path===Path.location_form )})} to={Path.location}><Link title="Location" onClick={sidebarHideShow} class="nav-link" to={Path.location}><i class="fas fa-th-large"></i> <span>Locations</span></Link></li>

                    <li className={classNames("", {'active' : (path===Path.offer_category || path===Path.offer_category_form || path===Path.offer_category_form_edit || path===Path.offer_categoryview || path===Path.offer_category_view)})} to={Path.offer_category}><Link title="Offers Category" onClick={sidebarHideShow} class="nav-link" to={Path.offer_category}><i class="fas fa-th-large"></i> <span>Categories</span></Link></li>

                    <li className={classNames("", {'active' : (path===Path.ads || path===Path.ads_form)})} to={Path.ads}><Link  title="Adds" onClick={sidebarHideShow} class="nav-link" to={Path.ads}><i class="fas fa-th-large"></i> <span>Ads</span></Link></li>

                    <li className={classNames("", {'active' : (path===Path.banners || path===Path.banner_form)})} to={Path.banners}><Link  title="Adds" onClick={sidebarHideShow} class="nav-link" to={Path.banners}><i class="fas fa-th-large"></i> <span>Banners</span></Link></li>

                    <li className={classNames("", {'active' : (path===Path.restaurant || path===Path.restaurant_form || path===Path.restaurant_form_edit || path===Path.restaurantview || path===Path.restaurant_view || path===Path.offer_form || path===Path.offer || path===Path.offerview)})} to={Path.restaurant}><Link title="Restaurant" onClick={sidebarHideShow} class="nav-link" to={Path.restaurant}><i class="fas fa-th"></i> <span>Businesses</span></Link></li>
                    <li className={classNames("", {'active' : (path===Path.notification || path===Path.notification || path===Path.notification || path===Path.notification || path===Path.notification || path===Path.notification || path===Path.notification || path===Path.notification)})} to={Path.notification}><Link title="Notifications" onClick={sidebarHideShow} class="nav-link" to={Path.notification}><i class="fas fa-bell"></i> <span>Notifications</span></Link></li>

                    {/* 

                    <li className={classNames("", {'active' : (path===Path.booking || path===Path.bookingview || path===Path.booking_view )})} to={Path.booking}><Link  title="Booking"  onClick={sidebarHideShow} class="nav-link" to={Path.booking}><i class="fas fa-th"></i> <span>Booking</span></Link></li>

                    <li className={classNames("", {'active' : (path===Path.subscription || path===Path.subscriptionview || path===Path.subscription_view)})} to={Path.subscription}><Link  onClick={sidebarHideShow} class="nav-link" to={Path.subscription} title="Subscription"><i class="fa fa-folder"></i> <span>Subscribers</span></Link></li> */}

                    <li className={classNames("", { 'active': (path === Path.cms_about || path === Path.cms_privacy_policy || path === Path.cms_terms) })} to={Path.cms_about}><Link class="nav-link" onClick={sidebarHideShow} to={Path.cms_about} title="CMS"><i class="fas fa-file"></i> <span>CMS</span></Link></li>
                    <li className={classNames("", {'active' : (path===Path.rating || path===Path.rating_view || path===Path.ratingview)})} to={Path.rating}><Link class="nav-link"  onClick={sidebarHideShow} to={Path.rating} title="Rating"><i class="fas fa-file"></i> <span>Rating</span></Link></li>
                    <li className={classNames("", {'active' : (path===Path.redeemoffer || path===Path.redeemoffer_view || path===Path.redeemofferview)})} to={Path.redeemoffer}><Link class="nav-link"  onClick={sidebarHideShow} to={Path.redeemoffer} title="Rating"><i class="fas fa-file"></i> <span>Redeemed Coupon</span></Link></li>

                   {/*  <li className={classNames("", {'active' : (path===Path.notification_form )})} to={Path.notification_form}><Link  title="Notification"  onClick={sidebarHideShow} class="nav-link" to={Path.notification_form}><i class="fa fa-bell"></i> <span>Notification</span></Link></li>
                    
                    <li className={classNames("", {'active' : (path===Path.contact_us || path===Path.contact_usview || path===Path.contact_us_view)})} to={Path.contact_us}><Link class="nav-link"  title="Contact Us" onClick={sidebarHideShow} to={Path.contact_us}><i class="fa fa-address-book"></i> <span>Contact Us</span></Link></li> */}


                </ul>
            </aside>
        </div>
    )
}

const mapStateToPros = (state, props) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        loader: state.persistStore.loader,
        user: state.Auth,
        profile: state.persistStore.profile,
    }
};

export default connect(
    mapStateToPros,
)(Sidebar);
