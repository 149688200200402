import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link, useHistory} from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import { Field, reduxForm, reset } from 'redux-form';
import LoadingButton from 'components/shared/LoadingButton';
import * as actions from 'store/actions';
import { Formik  } from 'formik';
import env from 'env.json';
import moment from "moment";

const renderField = ({
    input,
    placeholder,
    type,
    disabled,
    meta: { touched, error, warning }
}) => (
    <>
        <textarea {...input} type={type} placeholder={placeholder} disabled={disabled} className={`form-control ${touched  && error ? `is-invalid` : ''}`}/>
        
        {touched &&
            (error && <span className="invalid-feedback">{error}</span>)
        }
    </>
)

const View = (props) => {
    //get current date
    const todayDate = moment().format('YYYY-MM-DD')
    //history
    const history = useHistory();

    let editkey = props && props.match.params.id;
    const [sending, setSending] = useState(false);
    const { handleSubmit, submitting} = props;
    const [data, setData] = useState({});

    const MODULE_NAME = 'restaurant_offer';

    //get data
    async function getData() {
        try{
            let query={
                MODULE_NAME,
                id:editkey
            }
            setSending(true);
            props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getDetail(query)).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                setSending(false);
                setData(res.body);
            });
        }catch(err) {
            setSending(false);
            console.log(err);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

       useEffect(() => {
            if(editkey){
                getData();
            }
       }, []);

    return(
        <>
            <Helmet title="Offers" />
            <section className="section">
                <div className="section-header">
                    <h1>Offers</h1>
                </div>
                {!props.loader &&
                <div className="section-body">
                    <div className="row mt-sm-4">
                        <div className="col-md-12">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Offers Info :</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {data && 
                    <>
                    <div className="row margin-main-bar">
                        <div className="col-md-6">
                            <div className="card profile-widget booking">
                                <ul className="list-group list-group">
                                    <li className="list-group-item"><b>Title</b> 
                                        <span className="float-right">{data.title}</span>
                                    </li>
                                    <li className="list-group-item"><b>Offer Type</b> 
                                        <span className="float-right">{data && data.offer_type ? data.offer_type?.name : ''}</span>
                                    </li>
                                    <li className="list-group-item"><b>Start Date</b> 
                                        <span className="float-right">{data.start_date}</span>
                                    </li>
                                    <li className="list-group-item"><b>End Date</b> 
                                        <span className="float-right">{data.end_date}</span>
                                    </li>
                                    <li className="list-group-item"><b>Expiry Status</b> 
                                        <span className="float-right"><div className={`badge ${todayDate>data.end_date ? 'badge-danger' : 'badge-success'}`}>{todayDate>data.end_date ? 'Expired' : 'Active'}</div></span>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Icon :</div><br/>
                                        <a href={data.icon ? env.SERVER_URL+data.icon : ''} target="_blank"><img src={data && data.icon ? env.SERVER_URL+data.icon : '/assets/img/avatar/avatar-1.png'} className="img-box-details" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row margin-main-bar">
                        <div className="col-md-12">
                            <div className="card profile-widget booking">
                                <div className="profile-widget-description">
                                    <div className="profile-widget-name booking">Description :</div>
                                    <p>{data.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                    }
                    <div className="float-right">
                        <Link to={`${Path.offer}/${data.restaurant_id}`}>
                            <button
                                type="submit"
                                className="btn btn-primary btn-lg"
                            >
                        Back
                        </button>
                        </Link>
                    </div>
                    <br />
                </div>
                }
                </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader: state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

const ViewForm = reduxForm({
	form: 'view',
})(View);

export default connect(mapStateToProps, mapDispatchToProps)(ViewForm);