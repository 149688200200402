import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";
import * as actions from 'store/actions';
import SelectInput from 'components/shared/SelectInput';
import TextInput from 'components/shared/TextInput';
import LoadingButton from 'components/shared/LoadingButton';
import moment from "moment";
import env from 'env.json';

const Listing = (props) => {

    let user_id = props && props.match.params.user_id;
    //pagination
    const [ activePage, setActivePage ] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
    const [pageRange, setPageRange] = useState(5);

    //history
    const history = useHistory();

    //search record
    const [sortby, setSortby] = useState(1);
    const [clearSearch, setClearSearch] = useState(false);
    const [ values, setValues] = useState({
        search:"",
    });

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const [sending, setSending] = useState(false);
    const [data, setData] = useState([]);
    const MODULE_NAME = 'user';
    
     //get data
     async function getData() {
        try{
            let search = clearSearch==false ? values.search : '';
            let query={
                page: activePage,
                MODULE_NAME,
                URL_NAME:'redeem_coupon_list',
                sortby,
                search:search,
                user_id:user_id,
            }
           setSending(false);
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getList(query)).then((res) => {
               setSending(false);
               setClearSearch(false);
               props.dispatch(actions.persist_store({ loader:false}));
               setData(res.body.data);
               setItemsCountPerPage(res.body.per_page);
               setTotalItemsCount(res.body.total_count);
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
   }

   useEffect(() => {
       getData();
   }, [activePage, sortby, clearSearch]);

   const clearFilter = ()=>{
        setClearSearch(!clearSearch)
        setValues({
            search:""
        })
    }

    const handlePageChange = (page) => {
        setActivePage(page);
       window.scrollTo(0,0);
    }

    const handleSubmit_ = async (e) => {
        try{
            e.preventDefault();
            let query = {
                page: activePage,
                MODULE_NAME,
                URL_NAME:'redeem_coupon_view',
                sortby,
                search:values.search
            };
            props.dispatch(actions.persist_store({ loader:true}));
            setSending(true);
            await props.dispatch(AuthService.getList(query)).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                setSending(false);
                setData(res.body.data);
            });
        }catch(err) {
            console.log(err);
            setSending(false);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

    return(
        <>
            <Helmet title="Redeemed Coupons" />
            <section className="section">
                <div className="section-header">
                    <h1>Redeemed Coupon List</h1>
                </div>
                    <div className="section-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div class="card-header">
                                        <div className="col-md-10">
                                            <h4>Listing</h4>
                                        </div>
                                        <div className="col-md-2 select-box-size">
                                            <SelectInput
                                                name="sort_by"
                                                className="form-control"
                                                onChange={(value) => setSortby(value.target.value)}
                                            >
                                                <option value="" disabled>Sort by </option>
                                                <option value="0">Asc</option>
                                                <option value="1">Desc</option>
                                            </SelectInput>
                                        </div>
                                       {/*  <div className="col-md-4">
                                             <form onSubmit={handleSubmit_}>
                                                <div className="input-group search-bar">
                                                    <TextInput
                                                        name="search"
                                                        className="form-control"
                                                        placeholder="Search..." 
                                                        value={values.search}
                                                        onChange={handleChange}
                                                    >
                                                    </TextInput>
                                                    <div className="input-group-append">
                                                        <LoadingButton
                                                            type="submit"
                                                            className="btn btn-primary"
                                                            loading={sending}
                                                        >
                                                            {!sending ? <i className="fa fa-search"></i> : '' }
                                                        </LoadingButton>
                                                    </div>
                                                    <div className="input-group-append" style={{ marginLeft:'3px'} }>
                                                        <LoadingButton
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={clearFilter}
                                                        >
                                                            <i className="fa fa-times"></i>
                                                        </LoadingButton>
                                                    </div>
                                                </div>
                                            </form>
                                        </div> */}
                                        {/* <div className="col-md-2 main-btn-box">
                                            <Link to={Path.restaurant_type_form} className="btn btn-primary btn-sm float-right" title="Add">
                                                    <i className="fa fa-sm fa-plus"></i> Add
                                            </Link>
                                        </div> */}
                                    </div>
                                    <div className="card-body">
                                        {!props.loader && data.length>0 &&
                                            <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                <th scope="col">User</th>
                                                <th scope="col">Offer</th>
                                                <th scope="col">Icon</th>
                                                <th scope="col">Offer Type</th>
                                                <th scope="col">Redeemed Date</th>
                                                <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data.length>0  && data.map((item, i) => {
                                                return(
                                                    <tr key={i}>
                                                        <td>{item?.user?.first_name+' '+item?.user?.last_name}</td>
                                                        <td>{item?.restaurant_offer?.description}</td>
                                                        <td>
                                                        {item?.restaurant_offer?.icon ? <img src={item?.restaurant_offer?.icon && item?.restaurant_offer?.icon ? env.SERVER_URL+item?.restaurant_offer?.icon : '/assets/img/avatar/avatar-1.png'} className="img-size" />
                                                         : '---'}</td>
                                                        <td>{item?.restaurant_offer?.offer_type?.name}</td>
                                                        <td>{moment(item?.created_at).format('YYYY-MM-DD')}</td>
                                                        <td>
                                                            <Link to={`${Path.redeem_coupon_view}/${item.user_id}/${item.id}`} style={{ marginLeft:'10px'}} className="btn btn-info btn-sm" title="View">
                                                                <i className="fa fa-lg fa-eye"></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                            <div className="pagination-box-review">
                                                {(totalItemsCount>itemsCountPerPage) &&
                                                <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={itemsCountPerPage}
                                                    totalItemsCount={totalItemsCount}
                                                    pageRangeDisplayed={pageRange}
                                                    onChange={(page) => handlePageChange(page)}
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                />
                                                }
                                            </div>
                                            </table>
                                        }
                                        {!props.loader && data.length==0 && 
                                            <div className="col-md-6">
                                                <h6>Data not found....</h6>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader:state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing);