import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';
import AuthService from 'services';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";
import * as actions from 'store/actions';
import SelectInput from 'components/shared/SelectInput';
import TextInput from 'components/shared/TextInput';
import LoadingButton from 'components/shared/LoadingButton';
import Toggle from 'react-toggle';
import ReactToggle from './ReactToggle.css';
import env from 'env.json';
import moment from "moment";

const Listing = (props) => {

    //pagination
    const [ activePage, setActivePage ] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0);
    const [pageRange, setPageRange] = useState(5);
    //top picked record
    const [topPickedRecord, setTopPickedRecord] = useState([]);
    const [checkUncheck, setCheckUncheck] = useState(false);

    //history
    const history = useHistory();

    const MODULE_NAME = 'restaurant';
    //search record
    const [sortby, setSortby] = useState(1);
    const [clearSearch, setClearSearch] = useState(false);
    const [ values, setValues] = useState({
        search:"",
    });

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const [sending, setSending] = useState(false);
    const [data, setData] = useState([]);
     //get data
    async function getData() {
        try{
            let search = clearSearch==false ? values.search : '';
            let query={
                page: activePage,
                MODULE_NAME,
                sortby,
                search:search
            }
           setSending(false);
           props.dispatch(actions.persist_store({ loader:true}));
            await props.dispatch(AuthService.getList(query)).then((res) => {
               setSending(false);
               setClearSearch(false);
               props.dispatch(actions.persist_store({ loader:false}));
               setData(res.body.data);
               setItemsCountPerPage(res.body.per_page);
               setTotalItemsCount(res.body.total_count);
               let arrId= []
               for(let i of res.body.data){
                   if(i.top_pick==='1'){
                    arrId.push(i.id)
                }
                setTopPickedRecord(arrId)
            }
            });
 
        }catch(err) {
           setSending(false);
           props.dispatch(actions.persist_store({ loader:false}));
           console.log(err);
           if(err && err.data && err.data.message){
               swal("Oops!", err.data.message, "error");
           }
        }
    }

   useEffect(() => {
       getData();
       const storedActivePage = localStorage.getItem('activePage');
        if (storedActivePage && Number(storedActivePage) !== activePage) {
            setActivePage(Number(storedActivePage));
        }
   }, [activePage, sortby, clearSearch]);

   const clearFilter = ()=>{
        setClearSearch(!clearSearch)
        setValues({
            search:""
        })
    }

    const handlePageChange = (page) => {
        localStorage.setItem('activePage', page);
        setActivePage(page);
       window.scrollTo(0,0);
    }

    const handleEditClick = () => {
        // Store the activePage value in local storage
        localStorage.setItem('activePage', activePage);
    };

    const handleSubmit_ = async (e) => {
        try{
            e.preventDefault();
            let query = {
                page: activePage,
                MODULE_NAME,
                sortby,
                search:values.search
            };
            props.dispatch(actions.persist_store({ loader:true}));
            setSending(true);
            await props.dispatch(AuthService.getList(query)).then((res) => {
                props.dispatch(actions.persist_store({ loader:false}));
                setSending(false);
                setData(res.body.data);
            });
        }catch(err) {
            console.log(err);
            setSending(false);
            props.dispatch(actions.persist_store({ loader:false}));
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }

     //update user status
    const updateStatus = async (id) => {
    try{
        let query = {
            id: id,
            MODULE_NAME
        };
        await props.dispatch(AuthService.updateStatus(query)).then((res) => {
            swal("Success!", res.message, "success");
            getData();
        });
        }catch(err) {
            console.log(err);
            setSending(false);
            if(err && err.data && err.data.message){
                swal("Oops!", err.data.message, "error");
            }
        }
    }


     //delete
	const popupDelete = id =>{
		try {
			swal({
				title: "Are you sure?",
				text: "Are you sure to Delete?",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
			.then(willDelete => {
				if (willDelete) {
                    try{
                        let query = {
                            id: id,
                            MODULE_NAME
                        };
                        props.dispatch(AuthService.deleteData(query)).then((res) => {
                            swal("Deleted!", res.message, "success");
                            getData();
                        });
                    }catch(err) {
                        console.log(err);
                        setSending(false);
                        if(err && err.data && err.data.message){
                            swal("Oops!", err.data.message, "error");
                        }
                    }
                }
			});
		}
		catch(err) {
            console.log(err);
			swal("Oops!", "Something went wrong!", "error");
	    }
	}

    //top picked single multiple
    let arrIds = []
    const mark_unmark_ids = (e, id, type) => {
        const {checked} = e.target;
        if(type=='single'){
            topPickCheckedRecord(id, checked, "top_pick_all")
        } else if(type == 'multiple'){
            if(checked==true){
                setTopPickedRecord([...topPickedRecord, id])
                setCheckUncheck(!checkUncheck)
            }
            else{
                let index = topPickedRecord.indexOf(id)
                if(index > -1){
                    topPickedRecord.splice(index, 1)
                    setTopPickedRecord(topPickedRecord)
                    setCheckUncheck(!checkUncheck)
                }
            }
        } else {
            if(checked==true){
                data.map((item)=>{
                    return arrIds.push(item.id)
                }) 
                setCheckUncheck(!checkUncheck)
                setTopPickedRecord(arrIds)
            }else{
                arrIds = []
                setTopPickedRecord([])
                setCheckUncheck(!checkUncheck)
            }
        }
        return arrIds;
    }

    const topPickCheckedRecord = (id, checked) => {
        if(checked==true){
            setTopPickedRecord([...topPickedRecord, id])
            setCheckUncheck(!checkUncheck)
        }
        else{
            let index = topPickedRecord.indexOf(id)
            if(index > -1){
                topPickedRecord.splice(index, 1)
                setTopPickedRecord(topPickedRecord)
                setCheckUncheck(!checkUncheck)
            }
        }
    }

    const top_pick_all = (e) => {
        const selectedValue = e.target.value;
        topPickBusiness(topPickedRecord, 'top_pick_all')
    }

    const topPickBusiness  = async (id, type) =>{
        try {
            console.log(id, "==", type)
            if(type=='top_pick_all'){
                // if(id.length==0){
                //     swal({
                //         title: "Please select atleast one",
                //         buttons: true,
                //         dangerMode: true,
                //     })
                // }else{
                    swal({
                        title: "Are you sure?",
                        icon: "success",
                        buttons: true,
                        dangerMode: true,
                    })
                    .then(willDelete => {
                        if (willDelete) {
                            try{
                                let query = {
                                    id: id,
                                    MODULE_NAME,
                                    URL_NAME:'add_top_pick'
                                };
                                props.dispatch(AuthService.createData(query)).then((res) => {
                                    swal("Success!", res.message, "success");
                                    getData();
                                });
                            }catch(err) {
                                console.log(err);
                                setSending(false);
                                if(err && err.data && err.data.message){
                                    swal("Oops!", err.data.message, "error");
                                }
                            }
                        }
                    }); 
                // }
            }
        }catch(err) {
            console.log(err);
        }
    }

    return(
        <>
            <Helmet title="Business" />
            <section className="section">
                <div className="section-header">
                    <h1>Business List</h1>
                </div>
                    <div className="section-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="col-md-12">
                                            <h4>Listing</h4>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <button  className="btn btn-success btn-sm" title="Top Pick"  onClick={(e) => {top_pick_all(e)}}>Send Alerts
                                                </button>
                                            </div>
                                            <div className="col-md-2 select-box-size">
                                                <SelectInput
                                                    name="sort_by"
                                                    className="form-control"
                                                    onChange={
                                                        (value) => {setSortby(value.target.value);
                                                         handlePageChange(1);
                                                         setActivePage(1);
                                                    }}
                                                >
                                                    <option value="" disabled>Sort by </option>
                                                    <option value="AllBusiness">All Business</option>
                                                    <option value="Inactive">Inactive Business</option>
                                                    <option value="Active">Active Business</option>
                                                </SelectInput>
                                            </div>
                                            <div className="col-md-2 select-box-size">
                                                <SelectInput
                                                    name="sort_by"
                                                    className="form-control"
                                                    onChange={(value) => {
                                                        setSortby(value.target.value);
                                                        handlePageChange(1);
                                                        setActivePage(1);
                                                    }}
                                                >
                                                    <option value="" disabled>Sort by </option>
                                                    <option value="0">Name Asc</option>
                                                    <option value="1">Name Desc</option>
                                                    <option value="2">Date Asc</option>
                                                    <option value="3">Date Desc</option>
                                                    <option value="4">Address Asc</option>
                                                    <option value="5">Address Desc</option>
                                                </SelectInput>
                                            </div>
                                            <div className="col-md-4">
                                                <form onSubmit={handleSubmit_}>
                                                    <div className="input-group search-bar">
                                                        <TextInput
                                                            name="search"
                                                            className="form-control"
                                                            placeholder="Search..." 
                                                            value={values.search}
                                                            onChange={handleChange}
                                                        >
                                                        </TextInput>
                                                        <div className="input-group-append">
                                                            <LoadingButton
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                loading={sending}
                                                            >
                                                                {!sending ? <i className="fa fa-search"></i> : '' }
                                                            </LoadingButton>
                                                        </div>
                                                        <div className="input-group-append" style={{ marginLeft:'3px'} }>
                                                            <LoadingButton
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={clearFilter}
                                                            >
                                                                <i className="fa fa-times"></i>
                                                            </LoadingButton>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-md-2 main-btn-box">
                                                <Link to={Path.restaurant_form} className="btn btn-primary btn-sm float-right" title="Add">
                                                        <i className="fa fa-sm fa-plus"></i> Add
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div className="card-body">
                                    <div class="table-responsive">
                                        {!props.loader && data.length>0 &&
                                        <>
                                            <table class="table table-striped">
                                                <tr>
                                                    <th className="text-center pt-2">
                                                    <input type="checkbox" name="selection" onClick={(e)=> mark_unmark_ids(e, '','all')} className="custom-control" />
                                                    </th>
                                                    <th scope="col">Business Id</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Addres</th>
                                                    <th scope="col">$Sign</th>
                                                    <th scope="col">Created</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                                {data.length>0  && data.map((item, i) => {
                                                return(
                                                    <tr key={i}>
                                                        <td><input type="checkbox" name="selection" className="custom-control" onClick={(e)=> mark_unmark_ids(e, item.id, 'single')} checked={topPickedRecord.includes(item.id) ? 'checked' : ""} /></td>
                                                        <td>{item.id}</td>
                                                        <td>{item.name.length > 20 ? item.name.substring(0,20)+'...' : item.name}</td>
                                                        <td>{item.description.length > 20 ? item.description.substring(0,20)+'...' : item.description}</td>
                                                        <td>{item.location.length > 20 ? item.location.substring(0,20)+'...' : item.location}</td>
                                                        <td>{item.currency_type}</td>
                                                        <td>{item.created_at.split('T')[0]}</td>
                                                        <td>
                                                            <Toggle id='cheese-status' checked={item.status==1} onClick={()=> updateStatus(item.id)} />
                                                        </td>
                                                        <td>
                                                            <Link to={`${Path.restaurantview}/${item.id}`} style={{ marginLeft:'10px'}} className="btn btn-primary btn-sm" title="View">
                                                                <i className="fa fa-lg fa-eye"></i>
                                                            </Link>
                                                            <Link to={`${Path.restaurant_form}/${item.id}`} onClick={handleEditClick} style={{ marginLeft:'10px'}} className="btn btn-warning btn-sm" title="Edit">
                                                                <i className="fa fa-lg fa-edit"></i>
                                                            </Link>
                                                            <span style={{ marginLeft:'10px'}} className="btn btn-danger btn-sm" title="Delete" onClick={() => {popupDelete(item.id)}}>
                                                                <i className="fa fa-trash"></i>
                                                            </span> 
                                                            <Link to={`${Path.offer}/${item.id}`} style={{ marginLeft:'10px'}} className="btn btn-primary btn-sm" title="Add Offer">
                                                                <i className="fa fa-sm fa-plus"></i> Offers
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    )
                                                })}
                                            </table>
                                            <div className="pagination-box-review">
                                            {console.log(activePage)}
                                              {(totalItemsCount>itemsCountPerPage) &&
                                              <Pagination
                                                  activePage={activePage}
                                                  itemsCountPerPage={itemsCountPerPage}
                                                  totalItemsCount={totalItemsCount}
                                                  pageRangeDisplayed={pageRange}
                                                  onChange={(page) => handlePageChange(page)}
                                                  itemClass='page-item'
                                                  linkClass='page-link'
                                              />
                                              }
                                          </div>
                                          </>
                                        }
                                        {!props.loader && data.length==0 && 
                                            <div className="col-md-6">
                                                <h6>Data not found....</h6>
                                            </div>
                                        }
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
        loader:state.persistStore.loader,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing);